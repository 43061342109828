import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Trans } from "react-i18next";
import i18next from "i18next";
import Joi from "joi";
import JoiDate from "@joi/date";
import dayjs from "dayjs";
import Form from "../common/form";
import titles from "../../services/titles";
import customerService from "../../services/customerService";
import zipCodeService from "../../services/zipCodeService";
import { isValidArray } from "../common/check";

class RegisterClientForm extends Form {
    state = {
        data: {
            Email: "",
            Password: "",
            PasswordConfirm: "",
            IndicatorGender: "-1",
            Initials: "",
            FirstName: "",
            MiddleName: "",
            LastName: "",
            Birthday: "",
            Zip: "",
            HouseNumber: "",
            Street: "",
            City: "",
            Country: "",
            IsMailingAllowed: false,
            TelephoneMobile: "",
            TelephoneMobileCountryCode: ""
        },
        errors: {}
    };

    schema = Joi.object({
        Email: Joi.string()
            .trim()
            .required()
            .email({ minDomainSegments: 2, tlds: false })
            .label("label.email"),
        Password: Joi.string()
            .min(5)
            .max(25)
            .required()
            .strict()
            .label("label.password"),
        PasswordConfirm: Joi.string()
            .required()
            .strict()
            .valid(Joi.ref("Password"))
            .label("label.password.confirm"),
        IndicatorGender: Joi.string()
            .required()
            .invalid("-1")
            .label("label.title"),
        Initials: Joi.string()
            .required()
            .label("label.initials"),
        FirstName: Joi.string()
            .required()
            .min(2)
            .label("label.firstname"),
        MiddleName: Joi.string()
            .allow("")
            .label("label.middlename"),
        LastName: Joi.string()
            .min(2)
            .required()
            .label("label.lastname"),
        Birthday: Joi.extend(JoiDate)
            .date()
            .format("DD-MM-YYYY")
            .allow("")
            .min(dayjs().add(-120, "years"))
            .max(dayjs().add(-10, "days"))
            .label("label.birthday"),
        Zip: Joi.string()
            .trim()
            .required()
            .min(4)
            .max(7)
            .label("label.zipcode"),
        HouseNumber: Joi.string()
            .regex(/^[0-9].*/)
            .required()
            .label("label.housenumber"),
        Street: Joi.string()
            .required()
            .label("label.street"),
        City: Joi.string()
            .required()
            .label("label.city"),
        Country: Joi.string(),
        TelephoneMobileCountryCode: Joi.string(),
        IsMailingAllowed: Joi.boolean(),
        TelephoneMobile: Joi.string()
            .required()
            .trim()
            .min(9)
            .max(14)
            .label("label.mobilephone")
    });

    constructor(props) {
        super(props);

        this.buttonConfirm = React.createRef();
    }

    componentDidMount() {
        const { countries } = this.props;
        const { data } = this.state;

        if (isValidArray(countries, 1) && countries[0].CountryCode) {
            if (!data.Country || data.Country === "")
                data.Country = countries[0].CountryCode;

            if (
                !data.TelephoneMobileCountryCode ||
                data.TelephoneMobileCountryCode === ""
            )
                data.TelephoneMobileCountryCode = countries[0].CountryCode;
        } else {
            data.Country = "NL";
            data.TelephoneMobileCountryCode = "NL";
        }

        if (!countries) {
            customerService
                .fetchCountries()
                .then(countries_ => this.setState({ countries: countries_ }));
            this.setState({ data });

            return;
        }

        this.setState({ data, countries });
    }

    doChange = async (input, data, errors) => {
        this.fetchEmail(input, errors);
        this.fetchAddress(input, data, errors);
    };

    doSubmit = async () => {
        const { data, errors } = this.state;
        const { onConfirm } = this.props;

        this.buttonConfirm.current.disabled = true;

        const result = await customerService.create(data);
        if (!result) {
            toast.error(i18next.t("error.saving"));
            this.buttonConfirm.current.disabled = false;
            return;
        } else if (result.IsError && result.Message) {
            if (
                result.Message.indexOf("Invalid house number") !== -1 ||
                result.Message.indexOf("Invalid ZIP code") !== -1
            ) {
                if (errors) {
                    errors["Zip"] = i18next.t("error.zipcode.invalid");
                    this.buttonConfirm.current.disabled = false;
                    this.setState({ errors });
                }
                toast.error(i18next.t("error.zipcode.invalid"));
                this.buttonConfirm.current.disabled = false;
                return;
            }

            if (result.Message.indexOf("already in the database") !== -1)
                toast.error(i18next.t("error.zipcode.customer-known"));
            window.location = "/wachtwoord";
            this.buttonConfirm.current.disabled = false;
            return;
        }

        this.setState({ data: result, modify: true });
        toast.info(i18next.t("label.registered"));
        this.buttonConfirm.current.disabled = false;

        if (onConfirm) onConfirm(null, result);
        else window.location = "/";
    };

    doForgotten = async () => {
        const { data } = this.state;

        customerService.forgotPassword(data.Email);
        toast.success(i18next.t("label.password.requested"));
    };

    fetchEmail = async (input, errors) => {
        if (!(input && input.name === "Email")) return;

        if (errors["Email"]) return;

        const emailFound = await customerService.isRegistered(input.value.trim());
        if (!emailFound) {
            this.setState({ emailFound });
            return;
        }

        errors["Email"] = i18next.t("error.email.known");
        toast.error(i18next.t("error.email.known"));

        this.setState({ errors, emailFound });
    };

    render() {
        const { countries, emailFound } = this.state;

        customerService.formatTelephoneMobile(this.state.data, countries);

        const { Country, TelephoneMobileCountryCode } = this.state.data;

        const customMaskZipCode = zipCodeService.getZipCodeMask(Country);
        const customMaskMobilePhone = customerService.getMobileTelephoneMask(
            TelephoneMobileCountryCode
        );

        return (
            <form onSubmit={this.handleSubmit}>
                <Trans i18nKey="form.password.header">
                    <h3>E-mail en wachtwoord</h3>
                </Trans>
                <div className="fr">
                    {this.renderInput("Email", "label.email.you", {
                        showLabel: false,
                        useDiv: false,
                        lowerCase: true,
                        className: "full"
                    })}
                    <div className="message info">
                        <svg className="shape-icon-message-info">
                            <use xlinkHref="#shape-icon-message-info" />
                        </svg>{" "}
                        <Trans i18nKey="label.email.remark">
                            Wij gebruiken het opgegeven e-mail adres om een
                            afspraakbevestiging te sturen en als gebruikersnaam.
                        </Trans>
                    </div>
                </div>
                {emailFound ? (
                    <div>
                        {" "}
                        <br />
                        <br />
                        <span role="link">
                            <Link to="/inloggen">
                                <Trans i18nKey="label.login">Inloggen</Trans>
                            </Link>
                        </span>
                        {" | "}
                        <span role="link" onClick={() => this.doForgotten()}>
                            <Trans i18nKey="label.password.forgot">Wachtwoord vergeten</Trans>
                        </span>
                    </div>
                ) : (
                    <React.Fragment>
                        {this.renderInput("Password", "label.password", {
                            showLabel: false,
                            className: "full",
                            type: "password"
                        })}
                        {this.renderInput("PasswordConfirm", "label.password.confirm", {
                            showLabel: false,
                            className: "full",
                            type: "password"
                        })}
                        <Trans i18nKey="form.personal.header">
                            <h3>Persoonsgegevens</h3>
                        </Trans>
                        <div className="fr">
                            {this.renderSelect(
                                "IndicatorGender",
                                "label.title",
                                titles,
                                "-1",
                                {
                                    showLabel: false,
                                    useDiv: false
                                }
                            )}
                            {this.renderInput("Initials", "label.initials", {
                                size: 6,
                                showLabel: false,
                                upperCase: true,
                                maxLength: 10,
                                useDiv: false
                            })}
                        </div>
                        {this.renderInput("FirstName", "label.firstname", {
                            showLabel: false,
                            className: "full"
                        })}
                        {this.renderInput("MiddleName", "label.middlename", {
                            showLabel: false,
                            className: "full",
                            maxLength: 12
                        })}
                        {this.renderInput("LastName", "label.lastname", {
                            showLabel: false,
                            className: "full"
                        })}
                        {this.renderInput("Birthday", "label.birthday.mask", {
                            showLabel: false,
                            className: "full",
                            type: "date"
                        })}
                        <Trans i18nKey="form.address.header">
                            <h3>Adresgegevens</h3>
                        </Trans>
                        <div className="fr">
                            {this.renderInput("Zip", "label.zipcode", {
                                showLabel: false,
                                useDiv: false,
                                type: "zip",
                                upperCase: true,
                                customMask: customMaskZipCode
                            })}
                            {this.renderInput("HouseNumber", "label.housenumber", {
                                showLabel: false,
                                useDiv: false,
                                size: 6,
                                maxLength: 7,
                                upperCase: true
                            })}
                        </div>
                        {this.renderInput("Street", "label.street", {
                            showLabel: false,
                            className: "full"
                        })}
                        {this.renderInput("City", "label.city", {
                            showLabel: false,
                            className: "full"
                        })}
                        {isValidArray(countries, 2)
                            ? this.renderSelect(
                                "Country",
                                "label.country",
                                countries,
                                Country,
                                {
                                    className: "full",
                                    showLabel: false,
                                    useDiv: true
                                }
                            )
                            : null}

                        <h3>
                            <Trans i18nKey="label.mobilephone">Mobiel nummer</Trans>
                        </h3>
                        <div className="fr">
                            <div className="message info">
                                <svg className="shape-icon-message-info">
                                    <use xlinkHref="#shape-icon-message-info" />
                                </svg>{" "}
                                <Trans i18nKey="label.mobilephone.remark">
                                    Wij gebruiken dit nummer voor eventuele wijzigingen of vragen
                                    over afspraken.
                                </Trans>
                            </div>
                            {isValidArray(countries, 1)
                                ? this.renderSelect(
                                    "TelephoneMobileCountryCode",
                                    "label.country.code",
                                    countries,
                                    TelephoneMobileCountryCode,
                                    {
                                        showLabel: false,
                                        useDiv: false,
                                        useIndicator: true
                                    }
                                )
                                : null}
                            {this.renderInput("TelephoneMobile", "label.mobilephone", {
                                size: 10,
                                showLabel: false,
                                type: "tel",
                                useDiv: false,
                                customMask: customMaskMobilePhone,
                                showMask: true
                            })}
                        </div>
                        <hr />
                        {this.renderCheckBox("IsMailingAllowed", "checkbox.newsletter")}
                        {this.renderCheckBoxDisclaimerPrivacy()}
                        {this.renderButton(
                            this.props.onConfirm
                                ? "label.appointment.check"
                                : "label.register",
                            true,
                            false,
                            this.buttonConfirm
                        )}
                    </React.Fragment>
                )}
            </form>
        );
    }
}

export default RegisterClientForm;
