import http from "./httpService";

const { apiUrl, license, debug, disableSms } = window.env;

function apiEndpoint() {
    return !debug
        ? apiUrl +
        "/shortmessage/" +
        (window.branch ? window.branch : license) +
        "/"
        : "https://localhost:44357/ShortMessageService.svc/" +
        (window.branch ? window.branch : license) +
        "/";
}

export async function requestValidationCode(telephone, countryCode) {
    if (!telephone)
        return null;

    let queryString = `?telephone=${telephone}`;
    if (countryCode) queryString = queryString + `&countryCode=${countryCode}`;

    // If SMS is disabled request a code (GUID) without mobile phone
    if (disableSms || !telephone) queryString = "";

    const { IsError: isError, Value: validationCode } = await http.get(
        apiEndpoint() + "RequestValidationCode" + queryString,
        20000
    );

    if (isError) return null;

    return validationCode;
}

export async function requestValidationCodeRetry(guid, telephone, countryCode) {
    if (disableSms) return;

    let queryString = `?guid=${guid}&telephone=${telephone}`;
    if (countryCode) queryString = queryString + `&countryCode=${countryCode}`;

    http.get(apiEndpoint() + "RequestValidationCodeRetry" + queryString, 20000);
}

export async function isCodeValid(guid, validationCode) {
    let queryString = `?guid=${guid}&validationCode=${validationCode}`;

    if (!guid || !validationCode || !validationCode.length === 6 || disableSms)
        return null;

    const { IsError: isError, Value: success } = await http.get(
        apiEndpoint() + "IsCodeValid" + queryString
    );

    if (isError) return null;

    return success;
}

export default {
    requestValidationCode,
    requestValidationCodeRetry,
    isCodeValid
};
