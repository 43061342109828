import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { isValidArray } from "./common/check";
import { Trans } from "react-i18next";
import i18next from "i18next";
import dayjs from "dayjs";
import customerService from "../services/customerService";
import appointmentService from "../services/appointmentService";

const { subscription, disableCancellation } = window.env;

class MyAppointments extends Component {
    state = {};

    async componentDidMount() {
        let customer = customerService.getCurrentCustomer();

        const { language, countries } = this.props;

        customer = await customerService.refresh(customer, language, countries);

        if (!customer) window.location = "/uitloggen";

        customerService.fetchFamilyMembers(customer).then(customerFamilyMembers => {
            this.onCustomerBound(customer, customerFamilyMembers);
        });
    }

    onCustomerBound = (customer, customerFamilyMembers) => {
        if (!customer) {
            this.setState({ futureAppointment: null, futureAppointments: null });
            return;
        }

        const futureAppointments = customer.FutureAppointments
            ? customer.FutureAppointments
            : null;
        const futureAppointment = isValidArray(futureAppointments, 1)
            ? futureAppointments[0]
            : null;

        if (isValidArray(customerFamilyMembers, 1))
            this.setState({
                customer,
                customerFamilyMembers,
                futureAppointment,
                futureAppointments
            });
        else this.setState({ customer, futureAppointment, futureAppointments });
    };

    onCancelAppointment = (futureAppointment, action) => {
        const futureAppointments = [...this.state.futureAppointments];

        futureAppointments.forEach(item => (item.confirmCancel = null));

        const index = futureAppointments.indexOf(futureAppointment);
        futureAppointments[index].confirmCancel = action;

        this.setState({ futureAppointments });
    };

    handleCancelAppointment = async futureAppointment => {
        const result = await appointmentService.cancelAppointment(
            futureAppointment.Id
        );

        if (result && !result.IsError) {
            toast.success(i18next.t("label.appointment.cancelled"));

            const futureAppointments = this.state.futureAppointments.filter(
                f => f.Id !== futureAppointment.Id
            );
            this.setState({ futureAppointments });
        }
    };

    render() {
        document.title = i18next
            .t("navigation.myappointments")
            .replace("<0>", "")
            .replace("</0>", "");

        const { customer } = this.state;
        if (!customer) return null;

        const {
            customerFamilyMembers,
            futureAppointment,
            futureAppointments
        } = this.state;
        const hours = new Date().getHours();

        const customerFamilyMembersSelection = customerFamilyMembers
            ? customerFamilyMembers.filter(f => f.Id !== customer.Id)
            : null;

        let cardInformation = customer.CardInformation;
        const today = dayjs.utc(new Date()).local();
        if (cardInformation && cardInformation.DateExpire < today)
            cardInformation = null;

        const cardExpiry =
            i18next.t("label.valid-untill") +
            (cardInformation && cardInformation.DateExpire
                ? cardInformation.DateExpire.format("LL")
                : i18next.t("label.valid-indefinitely"));
        const welcomeTextFutureAppointment = futureAppointment
            ? i18next.t("myappointments.appointment.future", {
                description: futureAppointment.Description,
                date: futureAppointment.DateFormatted,
                time: futureAppointment.TimeFormatted
            })
            : null;

        return (
            <section id="myappointments" className="account">
                <header>
                    <h1>
                        {hours > 18
                            ? i18next.t("label.good-evening")
                            : hours > 12
                                ? i18next.t("label.good-afternoon")
                                : i18next.t("label.good-morning")},{" "}
                        {customer.IndicatorGender === "M"
                            ? i18next.t("label.mister")
                            : customer.IndicatorGender === "F"
                                ? i18next.t("label.madam")
                                : customer.FirstName}
                        {customer.MiddleName && customer.MiddleName.length > 0
                            ? " " + customer.MiddleName + " " + customer.LastName
                            : " " + customer.LastName}!{" "}
                        <span role="link">
                            <Trans i18nKey="myappointments.logout">
                                <Link to="/uitloggen">Dit ben ik niet</Link>
                            </Trans>
                        </span>
                    </h1>
                    {futureAppointment ? <p>{welcomeTextFutureAppointment}</p> : null}
                    <p>
                        <Link to="/" className="button">
                            <Trans i18nKey="label.appointment.new">
                                Maak een nieuwe afspraak
                            </Trans>
                        </Link>
                    </p>
                    {(customerFamilyMembersSelection && (
                        <p>
                            <Trans i18nKey="label.appointment.new.family">
                                Maak een afspraak voor iemand anders
                            </Trans>
                            <br />
                            {
                                (customerFamilyMembersSelection.map(
                                    customerFamilyMember => (
                                        <React.Fragment key={customerFamilyMember.Id}>
                                            <span>
                                                <Link
                                                    to={{ pathname: "/", state: { customerFamilyMember: customerFamilyMember } }}
                                                >
                                                    {customerFamilyMember.FirstName}
                                                </Link>
                                            </span>
                                            {customerFamilyMembersSelection.length === 1 ? (
                                                "."
                                            ) : customerFamilyMember.Id ===
                                                customerFamilyMembersSelection[
                                                    customerFamilyMembersSelection.length - 2
                                                ].Id ? (
                                                <Trans i18nKey="label.or">of</Trans>
                                            ) : customerFamilyMember.Id ===
                                                customerFamilyMembersSelection[
                                                    customerFamilyMembersSelection.length - 1
                                                ].Id ? (
                                                "."
                                            ) : (
                                                ", "
                                            )}
                                        </React.Fragment>
                                    ))
                                )
                            }
                        </p>))}

                    {customerFamilyMembersSelection && (
                        <div role="link" className="hasPopover">
                            <Trans i18nKey="label.appointment.new.family">
                                Maak een afspraak voor iemand anders
                            </Trans>
                            <div className="popover">
                                {customerFamilyMembersSelection.map(customerFamilyMember => (
                                    <div role="link" key={customerFamilyMember.Id}>
                                        <Link
                                            to={{ pathname: "/", state: { customerFamilyMember } }}
                                        >
                                            {customerFamilyMember.FirstName}
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </header>
                <div className="body">
                    <div id="clientcard">
                        <Trans i18nKey="myappointments.customer-benefits">
                            <h2>Klantvoordeel</h2>
                        </Trans>
                        {cardInformation && (
                            <Trans i18nKey="myappointments.customer-card">
                                <p>
                                    {{
                                        description: cardInformation.Description,
                                        barcode: cardInformation.Barcode,
                                        credits: cardInformation.Credits,
                                        cardExpiry
                                    }}
                                </p>
                            </Trans>
                        )}
                        {subscription && !customer.Subscriptions ? (
                            <Trans i18nKey="myappointments.subscription.none">
                                <p>
                                    <Link to="/abonnementen">abonnement</Link>
                                </p>
                            </Trans>
                        ) : (customer.Subscriptions && (
                            <table>
                                <tbody>
                                    <tr key="header">
                                        <th colSpan="2" style={{ padding: "0.5em" }}>
                                            <Trans i18nKey="subscriptions.current" key="header">
                                                Lopende abonnementen
                                            </Trans>
                                        </th>
                                    </tr>
                                    {customer.Subscriptions.map(subscription => (
                                        <tr key={subscription.Id}>
                                            <td>{subscription.Description}</td>
                                            {subscription.Bought && (
                                                <td>
                                                    {subscription.Consumed ? subscription.Consumed : "0"}{" "}
                                                    {" / "} {subscription.Bought}
                                                </td>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ))}
                        <table>
                            <tbody>
                                {customer.AmountDeposit && (
                                    <tr key="deposit">
                                        <td>
                                            <Trans i18nKey="myappointments.deposit">
                                                Resterend tegoed
                                            </Trans>
                                        </td>
                                        <td>
                                            €{" "}
                                            {(customer.AmountDeposit * 0.01)
                                                .toFixed(2)
                                                .replace(".", ",")}
                                        </td>
                                    </tr>
                                )}
                                {customer.AmountInvoice && (
                                    <tr key="open-invoice">
                                        <td>
                                            <Trans i18nKey="myappointments.open-invoice">
                                                Openstaand factuurbedrag
                                            </Trans>
                                        </td>
                                        <td>
                                            €{" "}
                                            {(customer.AmountInvoice * 0.01)
                                                .toFixed(2)
                                                .replace(".", ",")}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div id="appointments">
                        {customer.LastVisit &&
                            customer.LastProduct && (
                                <React.Fragment>
                                    <Trans i18nKey="myappointments.recent-history">
                                        <h2>Recente geschiedenis</h2>
                                    </Trans>
                                    {customer.LastVisit && (
                                        <Trans i18nKey="myappointments.last-visit">
                                            <p>{{ lastVisit: customer.LastVisitString }}</p>
                                        </Trans>
                                    )}
                                    {customer.LastProduct && (
                                        <Trans i18nKey="myappointments.last-product">
                                            <p>{{ lastProduct: customer.LastProduct }}</p>
                                        </Trans>
                                    )}
                                </React.Fragment>
                            )}
                        <Trans i18nKey="myappointments.future-appointments">
                            <h2>Toekomstige afspraken</h2>
                        </Trans>
                        {!futureAppointment ? (
                            <Trans i18nKey="myappointments.future-appointments.none">
                                <p>
                                    <strong>Er zijn geen toekomstige afspraken.</strong>
                                </p>
                            </Trans>
                        ) : (
                            <table>
                                <tbody>
                                    {futureAppointments.map(futureAppointment => (
                                        <tr key={futureAppointment.Id}>
                                            <th>{futureAppointment.DateFormatted}</th>
                                            <td>{i18next.t("label.at") + futureAppointment.TimeFormatted}</td>
                                            <td>
                                                {i18next.t("label.by") +
                                                    futureAppointment.EmployeePreferredFirstName}
                                            </td>
                                            {futureAppointment.confirmCancel ? (
                                                <React.Fragment>
                                                    <td>
                                                        <Trans i18nKey="label.are-you-sure" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <span
                                                            role="link"
                                                            onClick={() =>
                                                                this.handleCancelAppointment(futureAppointment)
                                                            }
                                                        >
                                                            <Trans i18nKey="label.yes" />
                                                        </span>{" "}
                                                        /{" "}
                                                        <span
                                                            role="link"
                                                            onClick={() =>
                                                                this.onCancelAppointment(
                                                                    futureAppointment,
                                                                    false
                                                                )
                                                            }
                                                        >
                                                            <Trans i18nKey="label.no" />
                                                        </span>
                                                    </td>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <td>{futureAppointment.Description}</td>
                                                    <td>
                                                        {!disableCancellation &&
                                                            futureAppointment.IsCancellationAllowed && (
                                                                <React.Fragment>
                                                                    <span
                                                                        role="link"
                                                                        onClick={() =>
                                                                            this.onCancelAppointment(
                                                                                futureAppointment,
                                                                                true
                                                                            )
                                                                        }
                                                                    >
                                                                        <Trans i18nKey="label.cancel" />
                                                                    </span>{" "}
                                                                    /{" "}
                                                                    <span
                                                                        role="link"
                                                                        onClick={() =>
                                                                            this.props.onAppointmentChange(
                                                                                futureAppointment
                                                                            )
                                                                        }
                                                                    >
                                                                        <Trans i18nKey="label.change" />
                                                                    </span>
                                                                </React.Fragment>
                                                            )}
                                                    </td>
                                                </React.Fragment>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                    {subscription && (
                        <div id="ctasubscription">
                            <aside>
                                <svg className="shape-icon-subscriptions">
                                    <use xlinkHref="#shape-icon-subscriptions" />
                                </svg>
                                <Trans i18nKey="subscriptions.promotion.short">
                                    <h2>Bespaar geld door een abonnement af te sluiten</h2>
                                    <ul>
                                        <li key="fixed-amount">Vast maandbedrag</li>
                                        <li key="always-neat">Er altijd verzorgd uitzien</li>
                                        <li key="automatic_collection">Automatische incasso</li>
                                        <li key="twelve-months">Minimaal 12 maanden</li>
                                        <li key="multiple-subscriptions">Kies 1 of meerdere abonnementen</li>
                                    </ul>
                                </Trans>
                                <p>
                                    <span role="link">
                                        <Trans i18nKey="link.more-information">
                                            <Link to="/abonnementen">Meer informatie</Link>
                                        </Trans>
                                    </span>
                                </p>
                            </aside>
                        </div>
                    )}
                </div>
            </section>
        );
    }
}

export default MyAppointments;
