import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import TreatmentGroup from "./treatmentGroup";
import treatmentGroups from "../../services/treatmentGroups";
import { isValidArray } from "../common/check";
import {
    isTreatmentSelected,
    treatmentDescription,
    translateDescription,
    treatmentsDepositTotal
} from "../../services/appointmentService";
import { fetchCustomerSubscriptions } from "../../services/subscriptionService";
import { formatPrice } from "../common/check";
import dayjs from "dayjs";
import Section, { Steps } from "./section";
import Warning from "./warning";

const { disableHairLength, subscription } = window.env;

export class Treatments extends Component {
    state = {
        treatmentGroups
    };

    constructor(props) {
        super(props);
        this.treatmentScroll = React.createRef();
    }

    async componentDidMount() {
        const { customer } = this.props;
        if (customer && subscription)
            fetchCustomerSubscriptions(customer).then(customerSubscriptions =>
                this.setState({ customerSubscriptions })
            );

        // Open/close accordion
        const accordionsHeader = document.querySelectorAll(
            ".accordion-item>header"
        );

        if (accordionsHeader) {
            accordionsHeader.forEach(element => {
                element.addEventListener("click", function () {
                    this.parentNode &&
                        this.parentNode.classList &&
                        this.parentNode.classList.toggle("open");
                });
            });
        }
    }

    closeAccordion() {
        const accordionsHeader = document.querySelectorAll(
            ".accordion-item>header"
        );

        accordionsHeader.forEach(
            accordion =>
                accordion.parentNode.classList.length === 2
                    ? accordion.parentNode.classList.toggle("open")
                    : null
        );
    }

    checkHairstyle(hairstyle, treatment) {
        if (!hairstyle || !treatment) return false;

        if (
            !treatment.IndicatorGender ||
            !hairstyle.IndicatorGender ||
            hairstyle.IndicatorGender === "O"
        )
            return true;

        if (
            disableHairLength ||
            !treatment.IndicatorHairlength ||
            hairstyle.IndicatorGender === "M"
        )
            return treatment.IndicatorGender === hairstyle.IndicatorGender;

        const hairLength = "F" + treatment.IndicatorHairlength;
        return hairLength === hairstyle.Indicator;
    }

    categorizeTreatments = (hairstyle, treatments) => {
        const { customerSubscriptions } = this.state;
        let { treatmentGroups } = this.state;

        treatmentGroups = treatmentGroups.sort((a, b) => a.order - b.order);

        if (!isValidArray(treatments)) return treatmentGroups;

        let treatmentSubscriptions = [];
        if (customerSubscriptions) {
            for (let customerSubscription of customerSubscriptions) {
                const treatmentSubscriptionList = treatments.filter(f => {
                    return f.Id === customerSubscription.IdTreatment;
                });

                if (treatmentSubscriptionList.length <= 0) continue;
                let treatmentSubscription = {};
                Object.assign(treatmentSubscription, treatmentSubscriptionList[0]);

                treatmentSubscription.Category = "Abonnementen";
                treatmentSubscription.Description = customerSubscription.Description;
                treatmentSubscriptions.push(treatmentSubscription);
            }
        }

        const treatmentBasic = treatments
            ? treatments.filter(f => {
                return f.Category === "Basic" && this.checkHairstyle(hairstyle, f);
            })
            : [];
        const treatmentColour = treatments
            ? treatments.filter(f => {
                return f.Category === "Colour" && this.checkHairstyle(hairstyle, f);
            })
            : [];
        const treatmentStylingWaving = treatments
            ? treatments.filter(f => {
                return (
                    f.Category === "Styling & Waving" &&
                    this.checkHairstyle(hairstyle, f)
                );
            })
            : [];
        const treatmentBeauty = treatments
            ? treatments.filter(f => {
                return f.Category === "Beauty" && this.checkHairstyle(hairstyle, f);
            })
            : [];
        const treatmentSpecial = treatments
            ? treatments.filter(f => {
                return f.Category === "Special" && this.checkHairstyle(hairstyle, f);
            })
            : [];

        treatmentGroups.find(f => f.key === '0').treatments = treatmentSubscriptions;
        treatmentGroups.find(f => f.key === '1').treatments = treatmentBasic;
        treatmentGroups.find(f => f.key === '2').treatments = treatmentColour;
        treatmentGroups.find(f => f.key === '3').treatments = treatmentStylingWaving;
        treatmentGroups.find(f => f.key === '4').treatments = treatmentBeauty;
        treatmentGroups.find(f => f.key === '5').treatments = treatmentSpecial;

        return treatmentGroups;
    };

    scrollTo = () => {
        if (this.treatmentScroll && this.treatmentScroll.current)
            this.treatmentScroll.current.scrollIntoView({
                behavior: "smooth",
                block: "start"
            });
    };

    render() {
        const {
            currentStep,
            language,
            branchParameters,
            customer,
            customerFamilyMembers,
            hairstyle,
            treatments,
            onChange,
            onSelect,
            onConfirm,
            onConfirmAdvice,
            onSelectCustomerFamilyMember
        } = this.props;

        let customerFamilyMember = this.props.customerFamilyMember;
        if (!customerFamilyMember) customerFamilyMember = customer;

        let customerFamilyMembersSelection = null;

        if (customerFamilyMember) {
            customerFamilyMembersSelection = customerFamilyMembers
                ? customerFamilyMembers.filter(f => f.Id !== customerFamilyMember.Id)
                : null;
        }

        if (currentStep === Steps.Treatment && isTreatmentSelected(treatments)) {
            this.scrollTo();
        }

        const treatmentGroups = this.categorizeTreatments(hairstyle, treatments);
        const categoryOpen =
            treatmentGroups[0].treatments.length > 0
                ? treatmentGroups[0].category
                : treatmentGroups[1].category;
        const longTime =
            customer && customer.LastVisit
                ? dayjs(customer.LastVisit).diff(dayjs(), "days") * -1 > 182
                : false;

        const treatmentAdvice =
            isValidArray(treatments) &&
            (!customer || longTime) &&
            treatments.find(f => f.Category === "Advice");

        const adviceDescription =
            treatmentAdvice && translateDescription(treatmentAdvice, language);

        const amountDeposit = treatmentsDepositTotal(treatments, customer);

        return (
            <Section
                id="treatment"
                step={Steps.Treatment}
                currentStep={currentStep}
                description={treatmentDescription(treatments, language)}
                onChange={onChange}
            >
                <div className="bodyLeftColumn" />
                <div className="bodyCenterColumn">
                    {branchParameters &&
                        branchParameters.OnlineStartText &&
                        branchParameters.OnlineStartText.trim().length > 10 && (
                            <Warning>{branchParameters.OnlineStartText}</Warning>
                        )}
                    {isValidArray(customerFamilyMembersSelection, 1) ? (
                        <div className="message note">
                            <svg className="shape-icon-message-info">
                                <use xlinkHref="#shape-icon-message-info" />
                            </svg>{" "}
                            <p>
                                <Trans i18nKey="treatment.for-familymember">
                                    Je gaat een afspraak maken voor
                                    {{ firstName: customerFamilyMember.FirstName }}.
                                </Trans>&nbsp;<Trans i18nKey="treatment.familymembers">
                                    Op dit adres staan ook andere mensen geregistreerd. Maak een
                                    afspraak voor{" "}
                                </Trans>
                                {customerFamilyMembersSelection.map(
                                    customerFamilyMemberSelection => (
                                        <React.Fragment key={customerFamilyMemberSelection.Id}>
                                            <span
                                                role="link"
                                                onClick={() =>
                                                    onSelectCustomerFamilyMember(
                                                        customerFamilyMemberSelection
                                                    )
                                                }
                                            >
                                                {customerFamilyMemberSelection.FirstName}
                                            </span>
                                            {customerFamilyMembersSelection.length === 1 ? (
                                                "."
                                            ) : customerFamilyMemberSelection.Id ===
                                                customerFamilyMembersSelection[
                                                    customerFamilyMembersSelection.length - 2
                                                ].Id ? (
                                                <Trans i18nKey="label.or">of</Trans>
                                            ) : customerFamilyMemberSelection.Id ===
                                                customerFamilyMembersSelection[
                                                    customerFamilyMembersSelection.length - 1
                                                ].Id ? (
                                                "."
                                            ) : (
                                                ", "
                                            )}
                                        </React.Fragment>
                                    )
                                )}
                            </p>
                        </div>
                    ) : null}
                    <div className="message note center" style={{ textAlign: "center" }}>
                        <svg className="shape-icon-message-note">
                            <use xlinkHref="#shape-icon-message-note" />
                        </svg>

                        {treatmentAdvice ? (
                            (!treatmentAdvice.Price || treatmentAdvice.Price === 0) ? (
                                <Trans i18nKey="treatment.choose-treatment-advice.free">
                                    <span>
                                        Kies minimaal 1 behandeling. Moeite met kiezen, klik dan{" "}
                                        <span
                                            role="link"
                                            title={adviceDescription}
                                            onClick={() => onConfirmAdvice(treatmentAdvice)}
                                        >
                                            hier
                                        </span>{" "}
                                        voor een gratis {{ adviceDescription }}.
                                    </span>
                                </Trans>
                            ) : (
                                <Trans i18nKey="treatment.choose-treatment-advice.paid">
                                    <span>
                                        Kies minimaal 1 behandeling. Moeite met kiezen, klik dan{" "}
                                        <span
                                            role="link"
                                            title={adviceDescription}
                                            onClick={() => onConfirmAdvice(treatmentAdvice)}
                                        >
                                            hier
                                        </span>{" "}
                                        voor een {{ adviceDescription }} (€{" "}
                                        {{ price: formatPrice(treatmentAdvice.Price) }}
                                        ).
                                    </span>
                                </Trans>
                            )
                        ) : (
                            <Trans i18nKey="treatment.choose-treatment">
                                Kies minimaal 1 behandeling
                            </Trans>
                        )}
                    </div>
                    <div className="accordion">
                        {treatmentGroups.map(treatmentGroup => (
                            <TreatmentGroup
                                language={language}
                                key={treatmentGroup.key}
                                treatmentGroup={treatmentGroup}
                                onSelect={onSelect}
                                isOpen={treatmentGroup.category === categoryOpen}
                            />
                        ))}
                    </div>
                    <footer>
                        {amountDeposit !== 0 && (
                            <Warning>
                                <Trans i18nKey="treatment.deposit">
                                    Voor deze keuze is een aanbetaling van €&nbsp;{{
                                        price: formatPrice(amountDeposit)
                                    }}
                                    vereist als je geen vaste klant bent.
                                </Trans>
                                {!customer && (
                                    <React.Fragment>
                                        <br />
                                        <Trans i18nKey="account.regular">Vaste klant?</Trans>
                                        &nbsp;
                                        <Link to="/inloggen">
                                            <Trans i18nKey="label.login">Inloggen</Trans>
                                        </Link>
                                    </React.Fragment>
                                )}
                            </Warning>
                        )}
                        <button
                            disabled={!isTreatmentSelected(treatments)}
                            onClick={() => onConfirm(treatments, customer)}
                            ref={this.treatmentScroll}
                        >
                            <svg className="shape-icon-check">
                                <use xlinkHref="#shape-icon-check" />
                            </svg>
                            <Trans i18nKey="treatment.choose-employee">
                                Klaar met kiezen? Kies een stylist
                            </Trans>
                        </button>
                    </footer>
                </div>
                {subscription && (
                    <div id="ctasubscription" className="bodyRightColumn">
                        <aside>
                            <svg className="shape-icon-subscriptions">
                                <use xlinkHref="#shape-icon-subscriptions" />
                            </svg>{" "}
                            <Trans i18nKey="subscriptions.promotion.short">
                                <h2>Bespaar geld door een abonnement af te sluiten</h2>
                                <ul>
                                    <li>Vast maandbedrag</li>
                                    <li>Er altijd verzorgd uitzien</li>
                                    <li>Automatische incasso</li>
                                    <li>Minimaal 12 maanden</li>
                                    <li>Kies 1 of meerdere abonnementen</li>
                                </ul>
                            </Trans>
                            <p>
                                <span role="link">
                                    <Trans i18nKey="link.more-information">
                                        <Link to="/abonnementen">Meer informatie</Link>
                                    </Trans>
                                </span>
                            </p>
                        </aside>
                    </div>
                )}
            </Section>
        );
    }
}

export default Treatments;
