import axios from "axios";
import axiosRetry from 'axios-retry'
//import * as rax from "retry-axios";
import { toast } from "react-toastify";
import logger from "./logService";

const { debug } = window.env;

if (!debug) {
    axios.defaults.auth = {
        username: "online",
        password: apiKey()
    };
}

let _suppressErrors = false;

function apiKey() {
    let key = process.env.REACT_APP_API_TOKEN;

    key = key.replace("!", "@");
    key = key.replace("U", "V");
    key = key.replace("A", "a");
    key = key.replace("e", "E");
    key = key.replace("U", "V");
    key = key.replace("t", "T");
    key = key.replace("ABCD", "NVWX");
    key = key.replace("U", "V");
    key = key.replace("PO", "lj");
    key = key.replace("P", "q");
    key = key.replace("e", "E");
    key = key.replace("e", "E");
    key = key.replace("B", "b");
    key = key.replace("^", "#");

    return key;
}

axios.interceptors.response.use(null, error => {
    if (_suppressErrors) return;

    const expectedError =
        error &&
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;

    if (!expectedError) {
        logger.log(error);
        toast.error(
            "Er is een onverwachte verbindingsfout opgetreden, probeer a.u.b. opnieuw."
        );
    }

    return Promise.reject(error);
});

window.onunhandledrejection = function (data) {
    if (_suppressErrors) return;
    if (data) logger.log(data.reason);
};

export async function get(url, timeout = 20000, suppressErrors) {
    try {
        const client = axios.create();
        const cancelToken = axios.CancelToken;
        const source = cancelToken.source();

        client.defaults.timeout = !debug ? timeout : 9000000;

        if (!debug) {
            //client.defaults.raxConfig = { instance: client };
            //rax.attach(client);
            axiosRetry(axios, { retries: 3 });
        }

        _suppressErrors = suppressErrors;
        //console.log("http get: " + url);

        const { data: value, status } = await client.get(url, {
            cancelToken: source.token
        });

        if (status === 204) {
            const result = {
                IsError: true,
                NoContent: true,
                ErrorMessage: "No content",
                ErrorCode: status,
                Value: null
            };

            _suppressErrors = false;
            return result;
        }

        // If value is a result already
        if (value && value.Value) return value;

        const result = {
            IsError: false,
            Value: value
        };

        _suppressErrors = false;
        return result;
    } catch (error) {
        error.message = error.message + " " + url;
        if (!suppressErrors) logger.log(error);

        const result = {
            IsError: true,
            ErrorMessage: error.message,
            ErrorCode: error.code,
            Value: error.response && error.response.data ? error.response.data : null,
            Error: new Error(error.message)
        };

        _suppressErrors = false;
        return result;
    }
}

export async function post(url, data, source, timeout = 20000) {
    try {
        const client = axios.create();
        const cancelToken = source ? source.token : null;

        client.defaults.timeout = !debug ? timeout : 9000000;
        if (!debug) {
            //client.defaults.raxConfig = { instance: client };
            //rax.attach(client);
            axiosRetry(axios, { retries: 3 });
        }

        //console.log("http post: " + url);
        const { data: value, status } = await client.post(url, data, {
            cancelToken
        });

        if (status === 204) {
            const result = {
                IsError: true,
                NoContent: true,
                ErrorMessage: "No content",
                ErrorCode: status,
                Value: null
            };

            return result;
        }

        // If value is a result already
        if (value && value.Value) return value;

        const result = {
            IsError: false,
            Value: value
        };

        return result;
    } catch (error) {
        error.message = error.message + " " + url;
        logger.log(error);

        const result = {
            IsError: true,
            ErrorMessage: error.message,
            ErrorCode: error.code,
            Value: error.response && error.response.data ? error.response.data : null,
            Error: new Error(error.message)
        };
        return result;
    }
}

export default {
    get,
    post,
    put: axios.put,
    delete: axios.delete
};
