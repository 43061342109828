import React, { Component } from "react";
import { Trans } from "react-i18next";
import customerService from "../services/customerService";
import Form from "../components/common/form";
import queryString from "query-string";
import { Rating } from 'react-simple-star-rating';


const { reviewLink } = window.env;

class Review extends Form {


    async componentDidMount() {

        this.fetchReview();
    }

    handleRating = (rating) => {
        this.setState({ rating });

        const idCustomerVisit = this.state.idCustomerVisit;
        this.setRating(idCustomerVisit, rating);
    };

    setRating = (idCustomerVisit, rating) => {
        customerService.reviewVisit(idCustomerVisit, rating);

        if (rating >= 4) {
            setTimeout(() => {
                window.location.replace(reviewLink);
            }, 1000);
        }
    }

    fetchReview() {
        const params = queryString.parse(window.location.search);
        const { id, rating } = params;
        const idCustomerVisit = id;

        if (rating)
            this.setState({ rating, idCustomerVisit });
        else
            this.setState({ rating: 3, idCustomerVisit });

        if (idCustomerVisit && rating) {
            this.setRating(idCustomerVisit, rating);
        }
    };

    doSubmit = () => {
        const { rating, idCustomerVisit, data } = this.state;
        const contactMe = !data.checkBoxNoContact;

        customerService.reviewVisit(idCustomerVisit, rating, contactMe, data.remark);
        this.setState({ rated: true });
    };


    render() {
        const { rating, rated } = this.state;

        if (rated || rating >= 4)
            return (<section className="account">
                <header>
                    <Trans i18nKey="review.header.good">
                        <h1>Bedankt voor het plaatsen van jouw review</h1>
                    </Trans>
                </header>
            </section>);

        return (
            <section className="account">
                <form onSubmit={this.doSubmit}>
                    <header>
                        <Trans i18nKey="review.header.bad">
                            <h1>Bedankt voor het plaatsen van jouw review</h1>
                        </Trans>
                    </header>
                    <div className="fr">
                        <Trans i18nKey="review.stars">
                            <strong>
                                <Trans i18nKey="review.stars" />
                            </strong>
                        </Trans>
                        <br />
                        <Rating
                            initialValue={rating}
                            onClick={this.handleRating}
                        />
                    </div>
                    <div className="fr">
                        <Trans i18nKey="review.description">
                            <strong>
                                <Trans i18nKey="review.description" />
                            </strong>
                        </Trans>
                    </div>
                    <div className="fr">
                        {this.renderTextArea("remark", "", {
                            maxLength: 3000, useDiv: false, rows: 5, cols: 40
                        })}
                        <br />
                        <div>
                            {this.renderCheckBox("checkBoxNoContact", "review.nocontact", false)}
                        </div>
                        <br />
                        {this.renderButton(
                            "Verstuur",
                            false,
                            true,
                            null
                        )}

                    </div>

                </form>
            </section>
        );
    }
}

export default Review;
