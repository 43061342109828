import React from "react";
//import InputMask from "react-input-mask";
import { InputMask } from "@react-input/mask";

const InputMasked = ({ name, label, error, ...rest }) => {
  return (
    <React.Fragment>
      <InputMask
        {...rest}
        name={name}
        id={name}
        placeholder={label}
        nospacebetweenchars="true"
      />
      {error && <div className="alert alert-danger">{error}</div>}
    </React.Fragment>
  );
};

export default InputMasked;
