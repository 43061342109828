import http from "./httpService";

const { apiUrl, debug } = window.env;
const apiEndpoint = !debug
  ? apiUrl + "/zipcode/"
  : "https://localhost:44357/ZipCodeService.svc/";

export async function fetchAddress(zipCode, houseNumberFull) {
  if (
    !zipCode ||
    !houseNumberFull ||
    zipCode.length < 6 ||
    houseNumberFull.length < 1 ||
    zipCode.length > 7
  )
    return null;

  let queryString = `?zipCode=${zipCode}&houseNumberFull=${houseNumberFull}`;

  const { IsError: isError, Value: addressValidated } = await http.get(
    apiEndpoint + "FetchAddress" + queryString
  );

  if (isError) return null;

  return addressValidated;
}

export function getZipCodeMask(country) {
  let customMaskZipCode = "____ ZZ";

  switch (country) {
    case "BE":
    case "B":
    case "LU":
    case "L":
    case "AT":
    case "CH":
      customMaskZipCode = "____";
      break;
    case "GB":
    case "UK":
      customMaskZipCode = "ZZ__";
      break;
    case "DE":
    case "D":
    case "FR":
    case "F":
    case "ES":
    case "SE":
    case "S":
      customMaskZipCode = "_____";
      break;
    default:
      break;
  }
  return customMaskZipCode;
}

export default {
  fetchAddress,
  getZipCodeMask
};
