import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { EmptyKey } from "../../services/constants";
import { isValidArray, fromISO } from "../common/check";
import GiftcardCta from "./giftcard-cta";
import Branch from "./branch";
import Hairstyle from "./hairstyle";
import Treatment from "./treatments";
import Employee from "./employee";
import Appointment from "./appointment";
import ReturningOrNew from "./returning-new";
import RegisterTempNew from "./register-temp-new";
import AppointmentConfirm from "./appointmentConfirm";
import AppointmentConfirmed from "./appointmentConfirmed";
import appointmentService from "../../services/appointmentService";
import shortMessageService from "../../services/shortMessageService";
import customerService from "../../services/customerService";
import hairstyles from "../../services/hairstyles";
import { Steps } from "./section";
import employeeService from "../../services/employeeService";
import i18next from "i18next";

const {
    hasMultipleBranches,
    disableHairLength,
    forceHairstyle,
    giftcard
} = window.env;

class Appointments extends Component {
    state = {};

    constructor(props) {
        super(props);

        this.hairstyle = null;
        this.treatmentControl = React.createRef();
        this.employeeControl = React.createRef();
        this.appointmentControl = React.createRef();
        this.state.treatments = this.props.treatments;
    }

    componentDidMount() {
        if (!this.state.nextStep) this.resetSteps();

        if (forceHairstyle)
            this.setState({
                hairstyle: hairstyles.filter(f => f.Indicator === forceHairstyle)[0]
            });

        const formFieldsError = document.querySelectorAll(".error");

        let customerFamilyMember = this.props.location && this.props.location.state.customerFamilyMember;

        if (formFieldsError)
            formFieldsError.forEach(function (element) {
                element.addEventListener("focus", function () {
                    element.nextSibling.classList.add("hide");
                });
                element.addEventListener("focusout", function () {
                    element.nextSibling.classList.remove("hide");
                });
            });

        this.setFutureAppointment();
        this.setFailed();
    }

    componentDidUpdate(prevProps, prevState) {
        const { customer } = this.props;
        const {
            customerFamilyMembers,
            nextStep,
            customerNew,
            idAppointment
        } = this.state;

        const href =
            window.location && window.location.href ? window.location.href : "";

        if (idAppointment && !href.includes("afspraak-wijzigen") && !href.includes("mislukt")) {
            this.handleAppointmentNew();
            return;
        }

        /*
        Object.entries(this.props).forEach(
          ([key, val]) =>
            prevProps[key] !== val && console.log(`Prop '${key}' changed to ${val}`)
        );
        if (this.state) {
          Object.entries(this.state).forEach(
            ([key, val]) =>
                  prevState[key] !== val && console.log(`State '${key}' changed to ${val}`)
          );
        }
        /**/

        if (nextStep == Steps.ReturningOrNew && customerNew)
            this.setState({ customerNew: null });

        if (nextStep >= Steps.ReturningOrNew) return;

        if (!customer) {
            if (customerFamilyMembers) this.setState({ customerFamilyMembers: null });

            return;
        }

        if (customer && customerFamilyMembers) return;

        this.setState({ customerFamilyMembers: [] });

        customerService.fetchFamilyMembers(customer).then(customerFamilyMembers => {
            if (isValidArray(customerFamilyMembers, 0)) {
                this.setState({ customerFamilyMembers });

                return;
            }
        });
    }

    resetSteps() {
        const treatments = this.state.treatments || this.props.treatments;

        if (!treatments) return;

        const { customer } = this.props;
        const { branch, hairstyle } = this.state;

        let step = Steps.Branch;
        let skipHairstyleSelect =
            (customer && customer.IndicatorHairlength) || hairstyle || forceHairstyle
                ? true
                : false;

        if (
            !hairstyle &&
            !disableHairLength &&
            skipHairstyleSelect &&
            (customer && customer.IndicatorGender === "F") &&
            (customer && !customer.IndicatorHairlength)
        )
            skipHairstyleSelect = false;

        if (!hasMultipleBranches) {
            step = Steps.Hairstyle;
            if (skipHairstyleSelect) step = Steps.Treatment;
        } else {
            if (branch) {
                step = Steps.Hairstyle;
                if (skipHairstyleSelect) step = Steps.Treatment;
            }
        }

        //console.log("setCurrentStep: " + step);
        this.setState({ nextStep: step });
    }

    async setFutureAppointment() {
        let { futureAppointment } = this.props;
        const { branches } = this.props;

        if (!futureAppointment) return;

        if (!futureAppointment.Customer)
            futureAppointment = await appointmentService.fetchFutureAppointment(
                futureAppointment.Id
            );

        if (!futureAppointment) return;

        if (!futureAppointment.IsCancellationAllowed) {
            window.location = "/niet-toegestaan";
            return;
        }

        const branch = branches.filter(
            f => f.Token === futureAppointment.License
        )[0];
        const { treatments } = this.state;

        if (branch) window.branch = branch.Token;

        futureAppointment.AppointmentTreatments.forEach(
            futureAppointmentTreatment => {
                treatments.forEach(treatment => {
                    if (
                        treatment.Id === futureAppointmentTreatment.IdComposition ||
                        (futureAppointmentTreatment.IdComposition === EmptyKey &&
                            treatment.Id === futureAppointmentTreatment.IdTreatment)
                    )
                        treatment.Selected = "selected";
                });
            }
        );

        const employees = await employeeService.fetchEmployees(
            treatments,
            null,
            this.props.language
        );

        if (!employees || employees === undefined || employees === null)
            return;

        const employeeSelection = employees.filter(
            f => f.Id === futureAppointment.IdEmployeePreferred
        );
        const employee =
            employeeSelection.length > 0
                ? employeeSelection[0]
                : employees.filter(f => f.Id === EmptyKey);

        if (employee.Id !== EmptyKey) employee.IsPreferred = true;

        this.hairstyle = hairstyles.filter(
            f => f.IndicatorGender === futureAppointment.IndicatorGender
        )[0];

        if (this.employeeControl && this.employeeControl.current)
            this.employeeControl.current.handleTreatmentsConfirm(treatments);

        if (this.appointmentControl && this.appointmentControl.current)
            this.appointmentControl.current.handleEmployeeSelect(employee, true);

        this.setState({
            idAppointment: futureAppointment.Id,
            branch,
            hairstyle: this.hairstyle,
            treatments,
            employee,
            availability: null,
            nextStep: Steps.Appointment
        });
    }

    async setFailed() {
        let { appointment } = this.props;
        const { branches } = this.props;

        if (!appointment) return;

        if (!appointment.Customer)
            appointment = await appointmentService.fetchFutureAppointment(
                appointment.Id
            );

        if (!appointment) return;

        const passby = appointment.Customer ? null : {};
        if (passby)
            passby.Name = appointment.CustomerFullName;

        const branch = branches.filter(
            f => f.Token === appointment.License
        )[0];
        const { treatments } = this.state;

        if (branch) window.branch = branch.Token;

        appointment.AppointmentTreatments.forEach(
            futureAppointmentTreatment => {
                treatments.forEach(treatment => {
                    if (
                        treatment.Id === futureAppointmentTreatment.IdComposition ||
                        (futureAppointmentTreatment.IdComposition === EmptyKey &&
                            treatment.Id === futureAppointmentTreatment.IdTreatment)
                    )
                        treatment.Selected = "selected";
                });
            }
        );

        const employees = await employeeService.fetchEmployees(
            treatments,
            null,
            this.props.language
        );

        const employeeSelection = employees.filter(
            f => f.Id === appointment.IdEmployeePreferred
        );
        const employee =
            employeeSelection.length > 0
                ? employeeSelection[0]
                : employees.filter(f => f.Id === EmptyKey);

        if (employee.Id !== EmptyKey) employee.IsPreferred = true;

        this.hairstyle = hairstyles.filter(
            f => f.IndicatorGender === appointment.IndicatorGender
        )[0];

        if (this.employeeControl && this.employeeControl.current)
            this.employeeControl.current.handleTreatmentsConfirm(treatments);

        if (this.appointmentControl && this.appointmentControl.current)
            this.appointmentControl.current.handleEmployeeSelect(employee, true);

        const resultCreateAppointment = {};
        resultCreateAppointment.IsError = true;
        resultCreateAppointment.PaymentFailed = true;
        const availability = {};
        availability.DateTime = fromISO(appointment.DateTimeFrom);

        this.setState({
            branch,
            customer: appointment.Customer,
            passby,
            hairstyle: this.hairstyle,
            treatments,
            employee,
            availability,
            confirming: null,
            resultCreateAppointment,
            nextStep: Steps.Confirmed
        });
    }

    setHairstyle(customer) {
        if (this.state.nextStep >= Steps.Employee) return;

        const hairstyle = forceHairstyle
            ? hairstyles.filter(f => f.Indicator === forceHairstyle)[0]
            : this.state.hairstyle;

        if (hairstyle) this.hairstyle = hairstyle;
        else if (customer) {
            this.hairstyle = hairstyles.filter(
                f => f.IndicatorGender === customer.IndicatorGender
            )[0];

            if (
                !disableHairLength &&
                customer.IndicatorGender === "F" &&
                customer.IndicatorHairlength
            ) {
                this.hairstyle = hairstyles.filter(
                    f =>
                        f.Indicator ===
                        customer.IndicatorGender + customer.IndicatorHairlength
                )[0];
            }
        }
    }

    handleAccordionChange = (step, collapseState, clicked) => {
        if (collapseState === "expanding" && clicked)
            this.setState({ nextStep: step });
    };

    handleBranchSelect = branch => {
        let refresh = window.branch !== branch.Token;
        let skipHairstyleSelect =
            this.props.customer || this.state.hairstyle ? true : false;

        // Return to the hairstyle (length) selection if the hairlength selection is on, the customer is female and no hairlength is known
        if (
            skipHairstyleSelect &&
            this.props.customer &&
            !disableHairLength &&
            this.props.customer.IndicatorGender === "F" &&
            !this.props.customer.IndicatorHairlength
        )
            skipHairstyleSelect = false;

        if (branch) window.branch = branch.Token;
        this.treatmentsUnselect();
        this.setState({
            branch,
            employee: null,
            availability: null,
            nextStep: skipHairstyleSelect ? Steps.Treatment : Steps.Hairstyle
        });

        if (refresh)
            appointmentService.fetchTreatments().then(treatments => {
                this.setState({
                    treatments
                });
            });
    };

    handleHairstyleSelect = hairstyle => {
        if (hairstyle) {
            this.treatmentsUnselect();
            this.setState({
                hairstyle,
                employee: null,
                availability: null,
                nextStep: Steps.Treatment
            });
        }
    };

    handleCustomerFamilyMemberSelect = customerFamilyMember => {
        if (this.hairstyle && customerFamilyMember) {
            if (
                this.hairstyle.IndicatorGender !== customerFamilyMember.IndicatorGender
            )
                this.setState({
                    hairstyle: hairstyles.filter(
                        f => f.IndicatorGender === customerFamilyMember.IndicatorGender
                    )[0]
                });

            if (!disableHairLength && customerFamilyMember.IndicatorGender === "F") {
                if (customerFamilyMember.IndicatorHairlength)
                    this.setState({
                        hairstyle: hairstyles.filter(
                            f =>
                                f.Indicator === "F" + customerFamilyMember.IndicatorHairlength
                        )[0]
                    });
                else this.setState({ nextStep: Steps.Hairstyle });
            }
        }

        this.setState({ customerFamilyMember });
    };

    treatmentsUnselect() {
        if (!isValidArray(this.state.treatments)) return;

        const treatments = [...this.state.treatments];
        treatments.forEach(treatmentUnselect => {
            treatmentUnselect.Selected = "";
        });

        if (treatments.Promotion) delete treatments.Promotion;
        if (treatments.Confirmed) delete treatments.Confirmed;

        this.setState({ treatments });
    }

    handleTreatmentSelect = (treatment, promotion, isAdvice) => {
        if (!isValidArray(this.state.treatments)) return;

        if (!treatment) {
            toast.info("");
            return;
        }

        if (
            promotion === "no" &&
            treatment.Promotion &&
            !!treatment.Promotion.Selected
        )
            return;

        const treatments = [...this.state.treatments];
        const index = treatments.findIndex(f => f.Id === treatment.Id);
        treatments[index] = { ...treatment };
        const category = treatments[index].Category;

        const isSelected = treatments[index].Selected === "selected";
        const isPromotionSelected =
            treatments[index].Promotion &&
            treatments[index].Promotion.Selected === "selected";

        // Unselect the advice treatment
        if (!isAdvice)
            treatments
                .filter(f => f.Category === "Advice")
                .forEach(treatmentUnselect => {
                    treatmentUnselect.Selected = "";
                });

        // Unselect all treatments for this category
        treatments.forEach(treatmentUnselect => {
            if (
                treatmentUnselect.Category === category &&
                treatmentUnselect.Selected === "selected"
            )
                treatmentUnselect.Selected = "";

            if (treatmentUnselect.Promotion)
                treatmentUnselect.Promotion.Selected = "";
        });

        if (isSelected && promotion === "no") treatments[index].Selected = "";
        else treatments[index].Selected = "selected";

        if (promotion !== "no") {
            treatments.Promotion = promotion;
            if (promotion) {
                treatments[index].Selected = "selected";

                if (isPromotionSelected) {
                    toast.warn(
                        treatments.Promotion.Description +
                        " is verwijderd uit de behandelingen."
                    );
                    delete treatments.Promotion;
                } else {
                    toast.success(
                        treatments.Promotion.Description +
                        " is toegevoegd aan de behandelingen."
                    );
                    treatments.Promotion.Selected = "selected";
                }
            }
        } else delete treatments.Promotion;

        if (treatments.Confirmed) delete treatments.Confirmed;

        this.setState({
            treatments,
            employee: null,
            availability: null
        });

        this.employeeControl.current.handleTreatmentSelect();
        this.appointmentControl.current.clearAvailabilities();

        return treatments;
    };

    handleTreatmentsConfirm = (treatments, customer) => {
        if (treatments) treatments.Confirmed = true;

        const amountDeposit = appointmentService.treatmentsDepositTotal(
            treatments,
            customer
        );

        this.setState({
            treatments,
            employee: null,
            availability: null,
            amountDeposit,
            nextStep: Steps.Employee
        });

        this.employeeControl.current.handleTreatmentsConfirm(treatments, customer);
    };

    handleTreatmentConfirmAdvice = treatment => {
        this.treatmentsUnselect();
        const treatments = this.handleTreatmentSelect(treatment, null, true);
        this.handleTreatmentsConfirm(treatments);
    };

    handleEmployeeConfirm = (employee, silentChair) => {
        this.appointmentControl.current.handleEmployeeSelect(employee);
        const availability = null;

        if (employee.Id !== EmptyKey) employee.IsPreferred = true;

        this.setState({ employee, availability, silentChair, nextStep: Steps.Appointment });
    };

    handleReturnToEmployees = () => {
        this.setState({
            employee: null,
            availability: null,
            nextStep: Steps.Employee
        });
    };

    handleAppointmentTimeSelect = availability => {
        const { customer, futureAppointment } = this.props;
        let { employee } = this.state;

        if (!employee || availability.IdEmployee !== employee.Id) {
            employee = this.employeeControl.current.fetchEmployee(
                availability.IdEmployee
            );
            if (employee) employee.IsPreferred = false;
        }

        shortMessageService
            .requestValidationCode(null)
            .then(validationGuid => this.setState({ validationGuid }));

        this.setState({
            availability,
            employee,
            customerNew: null,
            nextStep:
                customer || futureAppointment ? Steps.Confirm : Steps.ReturningOrNew
        });
    };

    handleLogin = customer => {
        this.props.onLogin(customer);
        this.setState({ nextStep: Steps.Confirm });
    };

    handleNewCustomer = () => {
        this.setState({ customerNew: true, nextStep: Steps.Register });
    };

    handleTempCustomer = (passby, customer) => {
        if (passby)
            this.setState({ passby, customerNew: null, nextStep: Steps.Confirm });
        if (customer) {
            this.props.onLogin(customer);
            this.setState({ nextStep: Steps.Confirm });
        }
    };

    handleAppointmentConfirm = (
        remark,
        passby,
        validationCode,
        language,
        idAppointment
    ) => {
        const { customer } = this.props;
        const {
            customerFamilyMember,
            treatments,
            availability,
            employee,
            validationGuid,
            silentChair
        } = this.state;

        this.setState({ confirming: true, nextStep: Steps.Confirmed });

        if (!validationCode || validationCode === "")
            validationCode = validationGuid;

        if (!idAppointment) {
            appointmentService
                .createAppointment(
                    treatments,
                    availability,
                    employee,
                    customer,
                    customerFamilyMember,
                    this.hairstyle,
                    validationCode,
                    silentChair,
                    remark,
                    passby && passby.Name,
                    passby && passby.Telephone,
                    passby && passby.TelephoneCountryCode,
                    passby && passby.Email,
                    language
                )
                .then(resultCreateAppointment =>
                    this.setState({ resultCreateAppointment, confirming: null })
                );
        } else {
            appointmentService
                .changeAppointment(
                    idAppointment,
                    treatments,
                    availability,
                    employee,
                    remark
                )
                .then(resultCreateAppointment =>
                    this.setState({ resultCreateAppointment, confirming: null })
                );
        }
    };

    handleAppointmentRetry = () => {
        const href =
            window.location && window.location.href ? window.location.href : "";

        if (href.includes("mislukt")) {
            window.location.replace("/");
            return;
        }

        const { employee } = this.state;
        this.setState({
            availability: null,
            customerNew: null,
            confirming: null,
            resultCreateAppointment: null,
            nextStep: Steps.Appointment
        });

        this.appointmentControl.current.handleEmployeeSelect(employee, true);
    };

    handleAppointmentNew = () => {
        this.setState({
            employee: null,
            availability: null,
            passby: null,
            customerNew: null,
            confirming: null,
            resultCreateAppointment: null,
            idAppointment: null
        });

        this.treatmentControl.current.closeAccordion();
        this.appointmentControl.current.clearAvailabilities();
        this.treatmentsUnselect();

        this.resetSteps();
    };

    render() {
        const { customerNew, nextStep } = this.state;
        const {
            branchParameters,
            branches,
            countries,
            customer,
            language,
            location
        } = this.props;

        let { futureAppointment } = this.props;

        const href =
            window.location && window.location.href ? window.location.href : "";
        if (futureAppointment && !href.includes("afspraak-wijzigen"))
            futureAppointment = null;

        document.title = i18next
            .t(
                !futureAppointment
                    ? "navigation.appointment"
                    : "navigation.appointment-change"
            )
            .replace("<0>", "")
            .replace("</0>", "");

        this.setHairstyle(customer);

        return (
            <React.Fragment>
                {giftcard && <GiftcardCta />}
                {hasMultipleBranches && !futureAppointment && (
                    <Branch
                        currentStep={nextStep}
                        customer={customer}
                        branch={this.state.branch}
                        branches={branches}
                        onChange={this.handleAccordionChange}
                        onSelect={this.handleBranchSelect}
                    />
                )}
                {!forceHairstyle && (
                    <Hairstyle
                        currentStep={nextStep}
                        customer={customer}
                        hairstyle={this.hairstyle}
                        onChange={this.handleAccordionChange}
                        onSelect={this.handleHairstyleSelect}
                    />
                )}
                <Treatment
                    ref={this.treatmentControl}
                    currentStep={nextStep}
                    language={language}
                    branchParameters={branchParameters}
                    customer={customer}
                    customerFamilyMember={this.state.customerFamilyMember}
                    customerFamilyMembers={this.state.customerFamilyMembers}
                    onSelectCustomerFamilyMember={this.handleCustomerFamilyMemberSelect}
                    hairstyle={this.hairstyle}
                    treatments={this.state.treatments}
                    onChange={this.handleAccordionChange}
                    onSelect={this.handleTreatmentSelect}
                    onConfirm={this.handleTreatmentsConfirm}
                    onConfirmAdvice={this.handleTreatmentConfirmAdvice}
                />
                <Employee
                    ref={this.employeeControl}
                    currentStep={nextStep}
                    language={language}
                    employee={this.state.employee}
                    onChange={this.handleAccordionChange}
                    onSelect={this.handleEmployeeConfirm}
                />
                <Appointment
                    ref={this.appointmentControl}
                    currentStep={nextStep}
                    language={language}
                    treatments={this.state.treatments}
                    employee={this.state.employee}
                    availability={this.state.availability}
                    idAppointment={this.state.idAppointment}
                    onChange={this.handleAccordionChange}
                    onSelect={this.handleAppointmentTimeSelect}
                    onReturnToEmployees={this.handleReturnToEmployees}
                />
                {!customer &&
                    !futureAppointment && (
                        <React.Fragment>
                            <ReturningOrNew
                                currentStep={nextStep}
                                language={language}
                                countries={countries}
                                customer={customer}
                                customerNew={customerNew}
                                location={location}
                                onChange={this.handleAccordionChange}
                                onLogin={this.handleLogin}
                                onNewCustomer={this.handleNewCustomer}
                            />
                            <RegisterTempNew
                                currentStep={nextStep}
                                countries={countries}
                                customerNew={customerNew}
                                amountDeposit={this.state.amountDeposit}
                                onChange={this.handleAccordionChange}
                                onConfirm={this.handleTempCustomer}
                            />
                        </React.Fragment>
                    )}
                <AppointmentConfirm
                    currentStep={nextStep}
                    language={language}
                    customer={customer}
                    passby={this.state.passby}
                    validationGuid={this.state.validationGuid}
                    treatments={this.state.treatments}
                    employee={this.state.employee}
                    availability={this.state.availability}
                    amountDeposit={this.state.amountDeposit}
                    idAppointment={this.state.idAppointment}
                    onChange={this.handleAccordionChange}
                    onConfirm={this.handleAppointmentConfirm}
                />
                <AppointmentConfirmed
                    currentStep={nextStep}
                    customer={customer}
                    passby={this.state.passby}
                    treatments={this.state.treatments}
                    availability={this.state.availability}
                    amountDeposit={this.state.amountDeposit}
                    confirming={this.state.confirming}
                    idAppointment={this.state.idAppointment}
                    resultCreateAppointment={this.state.resultCreateAppointment}
                    onChange={this.handleAccordionChange}
                    onRetry={this.handleAppointmentRetry}
                    onConfirm={this.handleAppointmentNew}
                />
            </React.Fragment>
        );
    }
}

export default Appointments;
