import React, { Component } from "react";
//import { ReCaptcha } from "react-recaptcha-google";
import { ReCaptcha } from 'react-recaptcha-v3'
import { toast } from "react-toastify";
import { Trans } from "react-i18next";
import i18next from "i18next";
import Joi from "joi";
import customerService from "../../services/customerService";
import zipCodeService from "../../services/zipCodeService";
//import logger from "../../services/logService";
import Input from "../common/input";
import InputMasked from "../common/inputMasked";
import AppContext from "../../AppContext";

class Form extends Component {
    state = {
        data: {},
        errors: {},
        loading: true,
        disableValidation: false
    };

    constructor(props, context) {
        super(props, context);

        this.recaptchaValid = false;
        //this.loadedReCaptcha = props.loadedReCaptcha;
        //this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        //this.verifyCallback = this.verifyCallback.bind(this);
    }

    componentDidMount() {
        //loadReCaptcha("6LeCBskUAAAAAJrO0gmkP569oEAsdlnzJHGs1oEN");
        //if (this.captchaControl) {
        //    this.captchaControl.reset();
        //}
    }

    validate = () => {
        if (!this.state || this.state.loading) return true;
        if (this.state.disableValidation) return true;

        const options = {
            abortEarly: false,
            allowUnknown: true
        };

        const { data } = this.state;
        let { errors } = this.state;

        if (data.Birthday === "  -  -    " || data.Birthday === "Invalid date") {
            data.Birthday = "";
            this.setState({ data });
        }

        if (
            errors === Object(errors) &&
            Object.getOwnPropertyNames(errors).length > 0
        )
            return errors;

        const { error } = this.schema.validate(this.state.data, options);

        if (!error) return null;

        errors = {};
        if (!error.details) return errors;

        for (let item of error.details) {
            errors[
                item.path[0] ? item.path[0] : item.context.subject.key
            ] = this.translateError(item.message);
        }

        return errors;

        /*
            const errors = {};
            const { account } = this.state;
        
            if (account.email.trim() === "") errors.email = "E-mail adres is verplicht.";
            if (account.password.trim() === "")
              errors.password = "Wachtwoord is verplicht.";
        
            return Object.keys(errors).length === 0 ? null : errors;
            */
    };

    validateProperty = ({ type, name, value }) => {
        if (!this.schema) return null;
        if (!this.state || this.state.loading) return null;

        const options = {
            abortEarly: false,
            allowUnknown: true
        };

        if (value === "  -  -    ") value = "";

        if (type === "checkbox") {
            value = value === "on" ? true : false;
        }

        if (this.state.disableValidation) return null;

        //const obj = { [name]: value };
        const schema = this.schema.extract(name);
        try {
            Joi.attempt(value, schema, null, options);
        } catch (error) {
            if (error.message) {
                if (error.message.includes("ref:")) {
                    const split = error.message.split("ref:");
                    const compare = this.state.data[split[1].replace('"', "")];
                    error.message = i18next.t("error.compare");

                    if (compare === value) return null;
                    else return this.translateError(error.message);
                } else return this.translateError(error.message);
            }
            return this.translateError(error.details[0].message);
        }

        return null;

        /*
              if (name === "email") {
              if (value.trim() === "") return "E-mail adres is verplicht.";
            }
            if (name === "password") {
              if (value.trim() === "") return "Wachtwoord is verplicht.";
            }
            */
    };

    translateError = message => {
        if (!message) return message;

        let field = message.match(/"([^']+)"/);

        if (!field || field.length < 1) return message;

        field = field[1];
        if (field.indexOf(" ") !== -1)
            field = field.substring(0, field.indexOf(" ") - 1);

        const fieldTranslated = i18next.t(field);

        message = message.replace(field, fieldTranslated);

        if (message.indexOf("fails to match") !== -1)
            return fieldTranslated + " " + i18next.t("error.invalid");

        message = message.replace(
            "is not allowed to be empty",
            i18next.t("error.empty")
        );
        message = message.replace("must be a string", i18next.t("error.empty"));
        message = message.replace(
            "must be in DD-MM-YYYY format",
            i18next.t("error.invalid")
        );
        message = message.replace(
            "length must be at least",
            i18next.t("error.min-length")
        );
        message = message.replace(
            "length must be less than or equal to",
            i18next.t("error.max-length")
        );
        message = message.replace(
            "must be larger than or equal to",
            i18next.t("error.min")
        );
        message = message.replace(
            "must be less than or equal to",
            i18next.t("error.max")
        );
        message = message.replace(
            "characters long",
            i18next.t("error.characters-long")
        );
        message = message.replace(
            "must be a valid email",
            i18next.t("error.email.invalid")
        );
        message = message.replace("must be a number", i18next.t("error.number"));

        return message;
    };

    verifyCallback = recaptchaToken => {
        if (recaptchaToken === null) {
            const message = i18next.t("error.recaptcha");
            toast.error(message);
        } else this.recaptchaValid = true;
    };

    handleSubmit = e => {
        e.preventDefault();

        if (this.state.disableSubmit) return;
        if (this.state.disableValidation) this.doSubmit();

        const errors = this.validate();
        this.setState({ errors: errors || {} });

        if (errors) {
            if (
                Object &&
                Object.keys &&
                Object.keys(errors) &&
                Object.keys(errors).length > 0
            )
                toast.error(errors[Object.keys(errors)[0]]);
            return;
        }

        const data = this.state.data;

        if (data && data.TelephoneMobile && data.TelephoneMobile.startsWith("06-") && data.TelephoneMobile.length < 11) {
            toast.error(i18next.t("label.mobilephone") + " " + i18next.t("error.min") + " 10"); return;
        }

        if (data && data.TelephoneMobile && data.TelephoneMobileCountryCode === "+") {
            data.TelephoneMobile = "+" + data.TelephoneMobile;
        }

        if (this.useReCaptcha && !this.recaptchaValid)
            return;

        this.doSubmit();
    };

    handleChange = ({ currentTarget: input }) => {
        const errors = { ...this.state.errors };
        const data = { ...this.state.data };

        data[input.name] = input.value;
        if (input.type === "checkbox") {
            if (data[input.name] === "on") data[input.name] = false;
            else if (data[input.name] === "off") data[input.name] = true;
        }

        if (input.type === "tel" && data[input.name].startsWith("06-06")) {
            data[input.name] = "06-";
        }

        const errorMessage = this.validateProperty(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];

        if (this.doChange) this.doChange(input, data, errors);
        this.setState({ data, errors });
    };

    handleBlur = ({ currentTarget: input }) => {
        const data = { ...this.state.data };
        data[input.name] = input && input.value && input.value.trim();
        this.setState({ data });
    };

    isReCaptchaReady = () => {
        return (
            AppContext.ReCaptchaLoaded
        );
    };

    renderButton(label, useReCaptcha = false, showCheck = false, ref) {
        this.useReCaptcha = useReCaptcha;
        label = i18next.t(label);

        return (
            <React.Fragment>
                <button ref={ref}>
                    {showCheck && (
                        <svg className="shape-icon-check">
                            <use xlinkHref="#shape-icon-check" />
                        </svg>
                    )}
                    {label}
                </button>
                {useReCaptcha &&
                    this.isReCaptchaReady() && (
                        <ReCaptcha
                            ref={element => {
                                this.recaptcha = element;
                            }}
                            sitekey="6LeCBskUAAAAAJrO0gmkP569oEAsdlnzJHGs1oEN"
                            action="doSubmit"
                            verifyCallback={this.verifyCallback}
                        />
                    )}
            </React.Fragment>
        );
    }

    renderTextArea(name, label, options = {}) {
        const className = options.className ? options.className : null;
        const useDiv = options.useDiv === false ? options.useDiv : true;
        const maxLength = options.maxLength ? options.maxLength : null;
        const rows = options.rows ? options.rows : null;
        const cols = options.cols ? options.cols : null;

        label = i18next.t(label);

        return useDiv ? (
            <div className="fr">
                <textarea
                    name={name}
                    id={name}
                    value={this.state.data[name]}
                    maxLength={maxLength ? maxLength : 200}
                    rows={rows}
                    cols={cols}
                    placeholder={label}
                    className={className}
                    onChange={this.handleChange}
                />
            </div>
        ) : (
            <textarea
                name={name}
                id={name}
                value={this.state.data[name]}
                maxLength={maxLength ? maxLength : 200}
                rows={rows}
                cols={cols}
                placeholder={label}
                className={className}
                onChange={this.handleChange}
            />
        );
    }

    renderCheckBox(name, label, checked) {
        return (
            <div className="fr checkboxradio">
                <label htmlFor={name}>
                    <input
                        type="checkbox"
                        name={name}
                        id={name}
                        defaultChecked={checked}
                        value={this.state.data[name] ? "on" : "off"}
                        onChange={this.handleChange}
                    />
                    <span><Trans i18nKey={label}>{label}</Trans></span>
                </label>
            </div>
        );
    }

    renderCheckBoxDisclaimerPrivacy() {
        const { disclaimerUrl, privacyUrl } = window.env;
        const name = "checkBoxDisclaimerPrivacy";

        return (
            <div className="fr checkboxradio">
                <label htmlFor={name}>
                    <input
                        type="checkbox"
                        name={name}
                        id={name}
                        defaultChecked={false}
                        value={this.state.checkBoxDisclaimerPrivacy}
                        required
                        onInvalid={e => {
                            e.target.setCustomValidity(
                                i18next.t("error.checkbox.terms-and-privacy")
                            );
                        }}
                        onChange={e => {
                            e.target.setCustomValidity(" ");
                            setTimeout(e => {
                                const checkBox = document.getElementById(
                                    "checkBoxDisclaimerPrivacy"
                                );
                                if (checkBox) checkBox.setCustomValidity("");
                            }, 200);
                            this.setState({
                                checkBoxDislaimerPrivacy: !this.state.checkBoxDisclaimerPrivacy
                            });
                        }}
                    />
                    <Trans i18nKey="checkbox.terms-and-privacy">
                        <a target="_blank" rel="noopener noreferrer" href={disclaimerUrl}>
                            algemene voorwaarden
                        </a>{" "}
                        en{" "}
                        <a target="_blank" rel="noopener noreferrer" href={privacyUrl}>
                            privacyverklaring
                        </a>
                        {"."}
                    </Trans>
                </label>
            </div>
        );
    }

    renderCheckBoxSubscription() {
        const { subscriptionUrl } = window.env;
        const name = "checkBoxSubscription";

        return (
            <div className="fr">
                <label htmlFor={name}>
                    <input
                        type="checkbox"
                        name={name}
                        id={name}
                        defaultChecked={false}
                        value={this.state.checkBoxSubscription}
                        required
                        onInvalid={e => {
                            e.target.setCustomValidity(
                                i18next.t("error.checkbox.subscription")
                            );
                        }}
                        onChange={e => {
                            e.target.setCustomValidity(" ");
                            setTimeout(e => {
                                document
                                    .getElementById("checkBoxSubscription")
                                    .setCustomValidity("");
                            }, 200);
                            this.setState({
                                checkBoxSubscription: !this.state.checkBoxSubscription
                            });
                        }}
                    />
                    <Trans i18nKey="checkbox.subscription">
                        <span>
                            Ik ga akkoord met de
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={subscriptionUrl}
                            >
                                abonnementsvoorwaarden
                            </a>
                            .
                        </span>
                    </Trans>
                </label>
            </div>
        );
    }

    renderCheckBoxAutomaticCollect() {
        const name = "checkBoxAutomaticCollect";

        return (
            <div className="fr checkboxradio">
                <label htmlFor={name}>
                    <input
                        type="checkbox"
                        name={name}
                        id={name}
                        defaultChecked={false}
                        value={this.state.checkBoxAutomaticCollect}
                        required
                        onInvalid={e => {
                            e.target.setCustomValidity(
                                i18next.t("error.checkbox.collection")
                            );
                        }}
                        onChange={e => {
                            e.target.setCustomValidity(" ");
                            setTimeout(e => {
                                document
                                    .getElementById("checkBoxAutomaticCollect")
                                    .setCustomValidity("");
                            }, 200);
                            this.setState({
                                checkBoxAutomaticCollect: !this.state.checkBoxAutomaticCollect
                            });
                        }}
                    />
                    <Trans i18nKey="checkbox.collection">
                        Ik, de betaler, geef toestemming voor automatische incasso.
                    </Trans>
                </label>
            </div>
        );
    }

    renderSelect(name, label, values, defaultValue, options = {}) {
        const showLabel = options.showLabel === false ? options.showLabel : true;
        const useDiv = options.useDiv === false ? options.useDiv : true;
        const useIndicator =
            options.useIndicator === true ? options.useIndicator : false;

        label = i18next.t(label);

        return useDiv ? (
            <div className="fr">
                {showLabel ? <label htmlFor={name}>{label}</label> : null}
                <select
                    name={name}
                    id={name}
                    key={name}
                    onChange={this.handleChange}
                    defaultValue={defaultValue}
                >
                    {values.map(value => (
                        <option value={value.Indicator} key={value.Indicator}>
                            {useIndicator ? value.Indicator : i18next.t(value.Description)}
                        </option>
                    ))}
                </select>
            </div>
        ) : (
            <React.Fragment>
                {showLabel ? <label htmlFor={name}>{label}</label> : null}
                <select
                    name={name}
                    id={name}
                    key={name}
                    onChange={this.handleChange}
                    defaultValue={defaultValue}
                >
                    {values.map(value => (
                        <option value={value.Indicator} key={value.Indicator}>
                            {useIndicator ? value.Indicator : i18next.t(value.Description)}
                        </option>
                    ))}
                </select>
            </React.Fragment>
        );
    }

    renderInputNative(
        name,
        label,
        size,
        showLabel,
        type,
        className,
        upperCase,
        lowerCase,
        customMask,
        maxLength,
        showMask
    ) {
        const { data, errors } = this.state;

        const mask = customMask
            ? customMask
            : type === "date"
                ? "dd-mm-yyyy"
                : type === "zip"
                    ? "____ ZZ"
                    : type === "iban"
                        ? "ZZ__ ZZZZ ____ ____ __"
                        : null;

        const isIban = type === "iban";

        if (type === "date" || type === "zip" || type === "iban") type = "text";
        label = i18next.t(label);

        const replacement = { d: /\d/, m: /\d/, y: /\d/, _: /\d/, Z: /\D/ };

        return (
            <React.Fragment>
                {showLabel ? <label htmlFor={name}>{label}</label> : null}
                {
                    mask ? (
                        <React.Fragment>
                            <InputMasked
                                name={name}
                                label={label}
                                value={data[name]}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                type={type}
                                className={className + (errors[name] ? " error" : "")}
                                mask={mask}
                                replacement={replacement}
                                showMask={showMask}
                                style={
                                    upperCase && isIban
                                        ? {
                                            textTransform: "uppercase",
                                            width: "50%",
                                            minWidth: "220px"
                                        }
                                        : upperCase
                                            ? {
                                                textTransform: "uppercase"
                                            }
                                            : lowerCase
                                                ? {
                                                    textTransform: "lowercase"
                                                }
                                                : null
                                }
                                maxLength={maxLength ? maxLength : null}
                                data-cip-id={isIban ? "iban" : null}
                            />
                            {errors[name] && (
                                <div className="popover">
                                    <svg className="shape-icon-message-info">
                                        <use xlinkHref="#shape-icon-message-info" />
                                    </svg>
                                    {errors[name]}
                                </div>
                            )}
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Input
                                name={name}
                                value={data[name]}
                                label={label}
                                size={size}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                type={type}
                                className={className + (errors[name] ? " error" : "")}
                                style={
                                    upperCase
                                        ? { textTransform: "uppercase" }
                                        : lowerCase
                                            ? { textTransform: "lowercase" }
                                            : null
                                }
                                maxLength={maxLength ? maxLength : null}
                            />
                            {errors[name] && (
                                <div className="popover">
                                    <svg className="shape-icon-message-info">
                                        <use xlinkHref="#shape-icon-message-info" />
                                    </svg>
                                    {errors[name]}
                                </div>
                            )}
                        </React.Fragment>
                    )
                }
            </React.Fragment >
        );
    }

    renderInput(name, label, options = {}) {
        const size = options.size ? options.size : null;
        const showLabel = options.showLabel === false ? options.showLabel : true;
        const type = options.type ? options.type : "text";
        const className = options.className ? options.className : null;
        const useDiv = options.useDiv === false ? options.useDiv : true;
        const upperCase = options.upperCase ? options.upperCase : false;
        const lowerCase = options.lowerCase ? options.lowerCase : false;
        const customMask = options.customMask ? options.customMask : null;
        const maxLength = options.maxLength ? options.maxLength : null;
        const showMask = options.showMask ? options.showMask : null;

        return useDiv ? (
            <div className="fr">
                {this.renderInputNative(
                    name,
                    label,
                    size,
                    showLabel,
                    type,
                    className,
                    upperCase,
                    lowerCase,
                    customMask,
                    maxLength,
                    showMask
                )}
            </div>
        ) : (
            this.renderInputNative(
                name,
                label,
                size,
                showLabel,
                type,
                className,
                upperCase,
                lowerCase,
                customMask,
                maxLength,
                showMask
            )
        );
    }

    fetchAddress = async (input, data, errors, ignoreExistingCustomer) => {
        if (!data || data === null) return;

        if (
            !(
                input &&
                (input.name === "Zip" ||
                    input.name === "HouseNumber" ||
                    input.name === "Country")
            )
        )
            return;

        /* If there is a country change, delete zip */
        if (input.name === "Country") {
            data.Zip = "";

            this.setState({ data, errors });

            return;
        }

        if (data.Country !== "NL") return;

        const zip = input.name === "Zip" ? input.value : data.Zip;
        const houseNumber =
            input.name === "HouseNumber" ? input.value : data.HouseNumber;

        /* length smaller then 4 gets validated by Joi,  */
        if (
            !zip ||
            !houseNumber ||
            zip === null ||
            houseNumber === null ||
            zip.trim().length <= 4 ||
            houseNumber.trim().length < 1
        ) {
            delete errors["Zip"];
            this.setState({ data, errors });

            return;
        }

        /* timmed length smaller then 6 */
        if (zip.trim().length < 6) {
            errors["Zip"] = i18next.t("error.zipcode.invalid");
            this.setState({ data, errors });

            return;
        }

        const addressValidated = await zipCodeService.fetchAddress(
            zip,
            houseNumber
        );

        if (addressValidated && addressValidated.IsFullyValidated) {
            data.Street = addressValidated.Address;
            data.City = addressValidated.City;
            if (input.name === "HouseNumber") data.HouseNumber = input.value;
            if (input.name === "Zip") data.Zip = input.value;

            if (!ignoreExistingCustomer) {
                const customerExists = await customerService.isRegisteredOnAddress(
                    data.IndicatorGender,
                    data.Initials,
                    data.FirstName,
                    data.LastName,
                    data.Zip,
                    data.HouseNumber,
                    data.Country,
                    data.Email
                );

                if (customerExists) {
                    input.value = "";
                    data.Zip = "";
                    data.HouseNumber = "";
                    data.City = "";

                    errors["Zip"] = i18next.t("error.zipcode.customer-known");
                    toast.error(i18next.t("error.zipcode.customer-known"));

                    this.setState({ errors });
                    return;
                }
            }

            delete errors["Zip"];
            this.setState({ data, errors });
        } else {
            errors["Zip"] = i18next.t("error.zipcode.invalid");
            this.setState({ errors });
        }
    };
}

export default Form;
