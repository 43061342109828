import React from "react";
import { toast } from "react-toastify";
import { isValidArray } from "./common/check";
import { Trans } from "react-i18next";
import i18next from "i18next";
import Joi from "joi";
import JoiDate from "@joi/date";
import dayjs from "dayjs";
import Form from "./common/form";
import titles from "../services/titles";
import customerService from "../services/customerService";
import zipCodeService from "../services/zipCodeService";
import UpdatePassword from "../components/forms/update-password";

class MyAccount extends Form {
    state = {
        data: {
            IndicatorGender: "-1",
            Initials: "",
            FirstName: "",
            MiddleName: "",
            LastName: "",
            Birthday: "",
            Zip: "",
            HouseNumber: "",
            Street: "",
            City: "",
            Country: "",
            Email: "",
            IsMailingAllowed: false,
            TelephoneMobile: "",
            TelephoneMobileCountryCode: ""
        },
        errors: {},
        loading: true
    };

    schema = Joi.object({
        IndicatorGender: Joi.string()
            .required()
            .invalid("-1")
            .label("label.title"),
        Initials: Joi.string()
            .required()
            .label("label.initials"),
        FirstName: Joi.string()
            .required()
            .min(2)
            .label("label.firstname"),
        MiddleName: Joi.string()
            .allow("")
            .label("label.middlename"),
        LastName: Joi.string()
            .required()
            .min(2)
            .label("label.lastname"),
        Birthday: Joi.extend(JoiDate)
            .date()
            .format("DD-MM-YYYY")
            .allow("")
            .min(dayjs().add(-120, "years"))
            .max(dayjs().add(-10, "days"))
            .label("label.birthday"),
        Zip: Joi.string()
            .trim()
            .required()
            .min(4)
            .max(7)
            .label("label.zipcode"),
        HouseNumber: Joi.string()
            .regex(/^[0-9].*/)
            .required()
            .label("label.housenumber"),
        Street: Joi.string()
            .required()
            .label("label.street"),
        City: Joi.string()
            .required()
            .label("label.city"),
        Country: Joi.string(),
        TelephoneMobileCountryCode: Joi.string(),
        Email: Joi.string()
            .trim()
            .required()
            .email({ minDomainSegments: 2, tlds: false })
            .label("label.email"),
        IsMailingAllowed: Joi.boolean(),
        TelephoneMobile: Joi.string()
            .required()
            .trim()
            .min(9)
            .max(14)
            .label("label.mobilephone")
    });

    async componentDidMount() {
        const { language, countries } = this.props;
        const { modify } = this.state;

        if (modify) {
            this.setState({ modify: false });
            return;
        }

        let customer = customerService.getCurrentCustomer();
        customer = await customerService.refresh(customer, language, countries);

        if (!customer || customer === null) {
            window.location = "/uitloggen";
            return;
        }

        const data = customer;

        if (isValidArray(countries, 1) && countries[0].CountryCode) {
            if (!data.Country || data.Country === null || data.Country === undefined || data.Country === "")
                data.Country = countries[0].CountryCode;

            if (
                !data.TelephoneMobileCountryCode ||
                data.TelephoneMobileCountryCode === ""
            )
                data.TelephoneMobileCountryCode = countries[0].CountryCode;
        } else {
            data.Country = "NL";
            data.TelephoneMobileCountryCode = "NL";
        }

        this.setState({ data, loading: false });
    }

    doChange = async (input, data, errors) => {
        this.fetchAddress(input, data, errors, true);
    };

    doSubmit = async () => {
        if (this.state.passwordChange) return;

        const { data } = this.state;
        const customer = await customerService.modify(data);
        if (!customer) {
            toast.error(i18next.t("error.saving"));
            return;
        }

        this.setState({ data: customer, modify: true });

        toast.info(i18next.t("label.saved"));
    };

    onPasswordChange = () => {
        this.setState({ passwordChange: true });
    };

    onPasswordCancel = () => {
        this.setState({ passwordChange: false });
    };

    render() {
        document.title = i18next
            .t("navigation.myaccount")
            .replace("<0>", "")
            .replace("</0>", "");

        if (this.state.loading || !this.state.data) return <React.Fragment />;

        const { countries } = this.props;

        customerService.formatTelephoneMobile(this.state.data, countries);

        const {
            IndicatorGender,
            IsMailingAllowed,
            Country,
            TelephoneMobileCountryCode
        } = this.state.data;

        const customMaskZipCode = zipCodeService.getZipCodeMask(Country);
        const customMaskMobilePhone = customerService.getMobileTelephoneMask(
            TelephoneMobileCountryCode
        );

        return (
            <section id="myaccount" className="account">
                <header>
                    <Trans i18nKey="navigation.myaccount">
                        <h1>Mijn account</h1>
                    </Trans>
                </header>
                <form onSubmit={this.handleSubmit}>
                    <div className="body">
                        <div id="myaccount-personal">
                            <Trans i18nKey="form.personal.header">
                                <h2>Persoonsgegevens</h2>
                            </Trans>
                            {this.renderSelect(
                                "IndicatorGender",
                                "label.title",
                                titles,
                                IndicatorGender
                            )}
                            {this.renderInput("Initials", "label.initials", {
                                size: 3,
                                upperCase: true,
                                maxLength: 10
                            })}
                            {this.renderInput("FirstName", "label.firstname", {
                                className: "full"
                            })}
                            {this.renderInput("MiddleName", "label.middlename", {
                                size: 5,
                                maxLength: 12
                            })}
                            {this.renderInput("LastName", "label.lastname", {
                                className: "full"
                            })}
                            {this.renderInput("Birthday", "label.birthday.mask", {
                                className: "full",
                                type: "date"
                            })}
                        </div>
                        <div id="myaccount-address">
                            <Trans i18nKey="form.address.header">
                                <h2>Adresgegevens</h2>
                            </Trans>
                            {this.renderInput("Zip", "label.zipcode", {
                                className: "full",
                                size: 8,
                                type: "zip",
                                upperCase: true,
                                customMask: customMaskZipCode
                            })}
                            {this.renderInput("HouseNumber", "label.housenumber", {
                                size: 8,
                                maxLength: 7,
                                upperCase: true
                            })}
                            {this.renderInput("Street", "label.street", {
                                className: "full"
                            })}
                            {this.renderInput("City", "label.city", {
                                className: "full"
                            })}
                            {isValidArray(countries, 2)
                                ? this.renderSelect(
                                    "Country",
                                    "label.country",
                                    countries,
                                    Country
                                )
                                : null}
                        </div>
                        <div id="myaccount-credentials">
                            <Trans i18nKey="form.account.header">
                                <h2>Account</h2>
                            </Trans>
                            {this.renderInput("Email", "label.email.username", {
                                className: "full",
                                type: "email",
                                lowerCase: true
                            })}
                            <div className="fr">
                                <div className="message">
                                    <svg className="shape-icon-message-info">
                                        <use xlinkHref="#shape-icon-message-info" />
                                    </svg>{" "}
                                    <Trans i18nKey="label.email.remark">
                                        Wij gebruiken het opgegeven e-mail adres om een
                                        afspraakbevestiging te sturen en als gebruikersnaam.
                                    </Trans>
                                </div>
                            </div>
                            <div className="fr">
                                {!this.state.passwordChange ? (
                                    <div role="link" onClick={() => this.onPasswordChange()}>
                                        <Trans i18nKey="label.password.change">
                                            Wachtwoord veranderen
                                        </Trans>
                                    </div>
                                ) : (
                                    <UpdatePassword
                                        account="true"
                                        guid={this.props.customer.Id}
                                        onCancel={this.onPasswordCancel}
                                    />
                                )}
                            </div>
                            {this.renderCheckBox(
                                "IsMailingAllowed",
                                "checkbox.newsletter",
                                IsMailingAllowed
                            )}
                            <hr />
                            <div className="fr">
                                <label htmlFor="phonecountry">
                                    <Trans i18nKey="label.mobilephone">Mobiel nummer</Trans>
                                </label>
                                {isValidArray(countries, 1)
                                    ? this.renderSelect(
                                        "TelephoneMobileCountryCode",
                                        "label.country.code",
                                        countries,
                                        TelephoneMobileCountryCode,
                                        {
                                            showLabel: false,
                                            useDiv: false,
                                            useIndicator: true
                                        }
                                    )
                                    : null}
                                {this.renderInput("TelephoneMobile", "label.mobilephone", {
                                    size: 10,
                                    showLabel: false,
                                    type: "tel",
                                    useDiv: false,
                                    customMask: customMaskMobilePhone,
                                    showMask: true
                                })}
                                <div className="message">
                                    <svg className="shape-icon-message-info">
                                        <use xlinkHref="#shape-icon-message-info" />
                                    </svg>{" "}
                                    <Trans i18nKey="label.mobilephone.remark">
                                        Wij gebruiken dit nummer voor eventuele wijzigingen of
                                        vragen over afspraken.
                                    </Trans>
                                </div>
                            </div>
                            <hr />
                            {this.renderCheckBoxDisclaimerPrivacy()}
                            {this.renderButton("label.change-data")}
                        </div>
                    </div>
                </form>
            </section>
        );
    }
}

export default MyAccount;
