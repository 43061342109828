import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import i18next from "i18next";
import LoginForm from "./forms/login";

class Login extends Component {
    state = {};

    render() {
        document.title = i18next
            .t("navigation.login")
            .replace("<0>", "")
            .replace("</0>", "");

        return (
            <section id="login" className="account">
                <header>
                    <Trans i18nKey="navigation.login">
                        <h1>Inloggen</h1>
                    </Trans>
                </header>
                <div className="body">
                    <LoginForm
                        language={this.props.language}
                        countries={this.props.countries}
                        onLogin={this.props.onLogin}
                        navigation={true}
                    />
                    <aside>
                        <Trans i18nKey="login.why-account">
                            <h3>Nog geen account?</h3>
                        </Trans>
                        <ul>
                            <Trans i18nKey="appointment.new-customer.why-register.list">
                                <li>sneller afspraken maken</li>
                                <li>inzicht in je afspraakgeschiedenis</li>
                                <li>op de hoogte blijven via optionele nieuwsbrief</li>
                            </Trans>
                        </ul>
                        <Link to="/registreer" className="button">
                            <Trans i18nKey="label.create-account">Maak account aan</Trans>
                        </Link>
                    </aside>
                </div>
            </section>
        );
    }
}

export default Login;
