import React, { Component } from "react";
import { formatPrice } from "../common/check";
import {
    translateDescription,
    translateRemark
} from "../../services/appointmentService";
import { Trans } from "react-i18next";
import i18next from "i18next";

class Treatment extends Component {
    state = { openend: false };

    showPopover = () => {
        this.setState({ opened: true });
    };

    hidePopover = () => {
        this.setState({ opened: false });
    };

    render() {
        const { language, treatment, onSelect } = this.props;
        const { payment, disableMinutes, prices, pricesColorUpward } = window.env;

        const treatmentDescription = translateDescription(treatment, language);
        const treatmentRemark = translateRemark(treatment, language);
        const promotionDescription = treatment.Promotion ? translateDescription(
            treatment.Promotion,
            language) : null;
        const promotionRemark = treatment.Promotion && treatment.Promotion.RemarkTranslations ? translateRemark(
            treatment.Promotion,
            language) : null;


        return (
            <tr key={treatment.Id} className={treatment.Selected}>
                <td onClick={() => onSelect(treatment, "no")}>
                    <input
                        key={treatment.Id}
                        type="radio"
                        name={treatment.Category}
                        id={treatment.Id}
                        checked={!!treatment.Selected}
                        readOnly={true}
                    />
                </td>
                {!treatment.Promotion ? (
                    <td onClick={() => onSelect(treatment, "no")}>
                        {treatmentDescription}
                        {treatmentRemark && treatmentRemark.length > 2 ? (
                            <React.Fragment>
                                <br />
                                <span style={{ fontStyle: "italic" }}>{treatmentRemark}</span>
                            </React.Fragment>
                        ) : null}
                    </td>
                ) : (
                    <td
                        className={this.state.opened ? "hasPopover opened" : "hasPopover"}
                        onMouseEnter={() => this.showPopover()}
                        onMouseLeave={() => this.hidePopover()}
                    >
                        <label htmlFor="care3">
                            {treatmentDescription}
                            {treatmentRemark && treatmentRemark.length > 2 ? (
                                <React.Fragment>
                                    <br />
                                    <span style={{ fontStyle: "italic" }}>{treatmentRemark}</span>
                                </React.Fragment>
                            ) : null}{" "}
                        </label>
                        <div className="popover">
                            <div
                                className="close"
                                role="link"
                                onClick={() => this.hidePopover()}
                            >
                                &times;
                            </div>
                            <p>
                                <Trans i18nKey="treatment.promotion-text">
                                    {{
                                        promotionDescription
                                    }}
                                </Trans>
                                &nbsp;
                                <span
                                    role="link"
                                    onClick={() => onSelect(treatment, treatment.Promotion)}
                                >
                                    <Trans i18nKey="treatment.select-as-well">
                                        Selecteer deze behandeling ook
                                    </Trans>
                                </span>
                                {promotionRemark && promotionRemark.length > 2 ? (
                                    <React.Fragment>
                                        <br /><br />
                                        <span style={{ fontStyle: "italic" }}>{promotionRemark}</span>
                                    </React.Fragment>
                                ) : null}{" "}

                            </p>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <input
                                                readOnly={true}
                                                key={treatment.Promotion.Id}
                                                id={treatment.Promotion.Id}
                                                type="checkbox"
                                                name="promotion"
                                                checked={!!treatment.Promotion.Selected}
                                                onClick={() => onSelect(treatment, treatment.Promotion)}
                                            />
                                        </td>
                                        <td>
                                            <label htmlFor="promotion">
                                                {translateDescription(treatment.Promotion, language)}
                                            </label>
                                        </td>
                                        {!disableMinutes && (
                                            <td style={{ textAlign: "right" }}>
                                                {treatment.Promotion.Minutes}{" "}
                                                <Trans i18nKey="treatment.minutes">minuten</Trans>
                                            </td>
                                        )}
                                        <td style={{ textAlign: "right" }}>
                                            {formatPrice(treatment.Promotion.Price)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                )}
                {!disableMinutes && (
                    <td
                        style={{ textAlign: "right" }}
                        onClick={() => onSelect(treatment, "no")}
                    >
                        {treatment.Minutes}&nbsp;<Trans i18nKey="treatment.minutes">
                            minuten
                        </Trans>
                    </td>
                )}
                {treatment.Category !== "Abonnementen" &&
                    (payment || prices) && (
                        <td
                            style={{ textAlign: "right" }}
                            onClick={() => onSelect(treatment, "no")}
                        >
                            {(treatment.Category === "Colour" ||
                                treatment.Category === "Special") &&
                                pricesColorUpward &&
                                i18next.t("label.from") + " "}
                            {formatPrice(treatment.Price)}
                        </td>
                    )}
            </tr>
        );
    }
}

export default Treatment;
