import React from "react";
import { Trans } from "react-i18next";
import Joi from "joi";
import Form from "../common/form";
import customerService from "../../services/customerService";
import shortMessageService from "../../services/shortMessageService";
import { isValidArray } from "../common/check";

class RegisterTempForm extends Form {
    state = {
        data: {
            EmailTemp: "",
            Name: "",
            TelephoneMobile: "",
            TelephoneMobileCountryCode: ""
        },
        errors: {}
    };

    schema = Joi.object({
        EmailTemp: Joi.string()
            .trim()
            .required()
            .email({ minDomainSegments: 2, tlds: false })
            .label("label.email"),
        Name: Joi.string()
            .required()
            .label("label.name"),
        TelephoneMobileCountryCode: Joi.string(),
        TelephoneMobile: Joi.string()
            .required()
            .trim()
            .min(9)
            .max(14)
            .label("label.mobilephone")
    });

    async componentDidMount() {
        const countries = await customerService.fetchCountries();
        this.setState({ countries });
    }

    doChange = async input => { };

    doSubmit = async () => {
        const { data, countries } = this.state;
        const { onConfirm, amountDeposit } = this.props;

        customerService.formatTelephoneMobile(data, countries);
        const useTelephone = !amountDeposit && data.TelephoneMobile;

        const validationGuid = await shortMessageService.requestValidationCode(
            useTelephone ? data.TelephoneMobile : null,
            useTelephone ? data.TelephoneMobileCountryCode : null
        );

        const passby = {
            Name: data.Name.trim(),
            Telephone: data.TelephoneMobile,
            TelephoneCountryCode: data.TelephoneMobileCountryCode,
            Email: data.EmailTemp.trim(),
            ValidationGuid: validationGuid
        };

        onConfirm(passby);
    };

    render() {
        const { countries } = this.state;

        customerService.formatTelephoneMobile(this.state.data, countries);

        let { TelephoneMobileCountryCode } = this.state.data;

        if (
            !TelephoneMobileCountryCode &&
            isValidArray(countries, 1) &&
            countries[0].CountryCode
        )
            TelephoneMobileCountryCode = countries[0].CountryCode;

        const customMaskMobilePhone = customerService.getMobileTelephoneMask(
            TelephoneMobileCountryCode
        );

        return (
            <form onSubmit={this.handleSubmit}>
                <div className="fr">
                    {this.renderInputNative(
                        "EmailTemp",
                        "label.email.you",
                        10,
                        false,
                        "email",
                        "full",
                        false,
                        true
                    )}
                    <div className="message info">
                        <svg className="shape-icon-message-info">
                            <use xlinkHref="#shape-icon-message-info" />
                        </svg>{" "}
                        <Trans i18nKey="label.email.remark">
                            Wij gebruiken het opgegeven e-mail adres om een
                            afspraakbevestiging te sturen en als gebruikersnaam.
                        </Trans>
                    </div>
                </div>
                {this.renderInput("Name", "label.name", {
                    showLabel: false,
                    className: "full"
                })}
                <div className="fr">
                    {isValidArray(countries, 1)
                        ? this.renderSelect(
                            "TelephoneMobileCountryCode",
                            "label.country.code",
                            countries,
                            TelephoneMobileCountryCode,
                            {
                                showLabel: false,
                                useDiv: false,
                                useIndicator: true
                            }
                        )
                        : null}
                    {this.renderInput("TelephoneMobile", "label.mobilephone", {
                        size: 10,
                        showLabel: false,
                        type: "tel",
                        useDiv: false,
                        customMask: customMaskMobilePhone,
                        showMask: true
                    })}
                    <div className="message info">
                        <svg className="shape-icon-message-info">
                            <use xlinkHref="#shape-icon-message-info" />
                        </svg>{" "}
                        <Trans i18nKey="label.mobilephone.remark">
                            Wij gebruiken dit nummer voor eventuele wijzigingen of vragen over
                            afspraken.
                        </Trans>
                    </div>
                </div>
                {this.renderCheckBoxDisclaimerPrivacy()}
                {this.renderButton("label.appointment.check", true, true)}
            </form>
        );
    }
}

export default RegisterTempForm;
